import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import Lottie from 'lottie-react';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { Done as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { useHistory } from 'react-router-dom';
import 'assets/scss/custom/done.scss';

const Done = ({ createdBot, setCreatedBot, isPortfolio = false }) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const queryParameters = new URLSearchParams(window.location.search);
  const exchangeUrl = queryParameters.get('exchange') || 'binance';
  const routerHistory = useHistory();

  const goBack = () => {
    queryParameters.delete('id');
    routerHistory.push(`?${queryParameters.toString()}`);
    setCreatedBot();
  };

  return (
    <>
      <Row
        className="align-items-center mt-6 mb-6 gutter-x-0"
        style={{ textAlign: 'center' }}
      >
        <Col>
          <Row className="align-items-center">
            <Col lg={12} className="d-flex justify-content-center">
              <Lottie
                animationData={checkPrimaryLight}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
          </Row>
          <h2 className="fw-normal text-900 mt-2">
            {isPortfolio ? textLang.your[lang] : textLang.yourPersonal[lang]}
            <strong>{isPortfolio ? 'AI Portfolio' : createdBot.name}</strong>
            {textLang.running[lang]}
          </h2>
          <h4 className="fw-normal text-700 mt-2">{textLang.check[lang]}</h4>
        </Col>
      </Row>
      <Flex justifyContent="lg-center" className="mt-5">
        {!isPortfolio && (
          <IconButton
            onClick={() => goBack()}
            variant="outline-secondary"
            className="me-2 mb-1 "
            icon="hammer"
            iconAlign="left"
            transform="shrink-3"
          >
            {textLang.newBot[lang]}
          </IconButton>
        )}
        <IconButton
          to={exchangeUrl === 'virtual' ? '/virtual-mode' : '/wallet'}
          as={Link}
          variant="outline-secondary"
          className="me-2 mb-1 "
          icon="chart-pie"
          iconAlign="left"
          transform="shrink-3"
        >
          {exchangeUrl === 'virtual'
            ? textLang.walletVirtualLink[lang]
            : textLang.walletLink[lang]}
        </IconButton>
      </Flex>
    </>
  );
};

Done.propTypes = {
  createdBot: PropTypes.object.isRequired,
  setCreatedBot: PropTypes.func.isRequired,
  isPortfolio: PropTypes.bool
};
export default Done;
