import React from 'react';
import PropTypes from 'prop-types';
import 'assets/scss/custom/ButtonGrid.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const interpolateColor = (color1, color2, factor) => {
  var result = color1
    .slice(1)
    .match(/.{2}/g)
    .map(hex => parseInt(hex, 16));
  return (
    '#' +
    result
      .map((start, i) => {
        var end = parseInt(color2.slice(1).match(/.{2}/g)[i], 16);
        var val = Math.round((1 - factor) * start + factor * end).toString(16);
        return val.length === 1 ? '0' + val : val;
      })
      .join('')
  );
};

const ButtonGrid = ({
  actives = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  colored = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  click = false,
  hover = false,
  buttonSize = 'md',
  className = '',
  selected = null,
  action = () => {}
}) => {
  const buttons = [];
  const color1 = '#5cff5a';
  const color2 = '#ff4645';

  for (let i = 0; i <= 10; i++) {
    const isInColorRange = colored.includes(i);
    const color = isInColorRange
      ? interpolateColor(color1, color2, (i - 1) / 9)
      : 'transparent';
    const border = isInColorRange ? 'none' : '1px solid var(--falcon-800)';
    actives.includes(i)
      ? buttons.push(
          <span
            className="justify-content-center"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
            onClick={() => isInColorRange && action(i)}
          >
            <button
              key={i}
              style={{ backgroundColor: color, border: border }}
              className={classNames(
                `square-button me-1 button-size-${buttonSize}`,
                {
                  'square-button-hover': hover && isInColorRange,
                  'square-button-click': click && isInColorRange,
                  'square-button-selected': selected === i
                }
              )}
            >
              {i}
            </button>
            {selected === i && <FontAwesomeIcon icon={'caret-up'} />}
          </span>
        )
      : null;
  }

  return (
    <div
      className={`button-container ${className}`}
      style={selected != null ? { alignItems: 'flex-start' } : {}}
    >
      {buttons}
    </div>
  );
};

ButtonGrid.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
  colorStart: PropTypes.number,
  colorEnd: PropTypes.number,
  click: PropTypes.bool,
  hover: PropTypes.bool,
  buttonSize: PropTypes.oneOf(['sm', 'md', 'lg']),
  className: PropTypes.string,
  selected: PropTypes.number,
  action: PropTypes.func,
  actives: PropTypes.arrayOf(PropTypes.number),
  colored: PropTypes.arrayOf(PropTypes.number)
};

export default ButtonGrid;
