export const exclusive_coins_low = {
  INITIAL_DATE: '2022-01-01',
  INITIAL_WALLET: 10000,
  GAIN: 96.68,
  VOLUME: 2911079.86,
  ORDERS: 1906,
  PAID_FEES: 2183.31,
  MIN_LEVEL: 9,
  MAXIMUM: 21066.88,
  MINIMUM: 7739.74,
  MAX_DRAWDOWN: 26.52,
  SHARPE: 1.81,
  BETA: 0.3,
  AVG_MONTHLY_RETURN: 2.51,
  MONTHLY_RETURNS: [
    -4.4, 2.13, 3.41, -5.11, -3.95, -9.26, 18.45, 0.31, -4.0, 7.03, -1.17,
    -4.77, 15.2, -3.73, 4.47, 2.25, -4.12, -0.12, 3.59, -5.0, -1.8, 17.33, 1.41,
    17.2, 5.14, 19.93, 8.43, -7.85, 5.65, -3.86, 4.87
  ],
  MIN_ALLOCATION: {
    BTC: 0.0,
    ETH: 0.0,
    BNB: 0.32,
    SOL: 0.0,
    USDT: 50.53
  },
  AVG_ALLOCATION: {
    BTC: 8.2,
    ETH: 11.62,
    BNB: 0.68,
    SOL: 8.3,
    USDT: 71.19
  },
  MAX_ALLOCATION: {
    BTC: 48.97,
    ETH: 48.97,
    BNB: 3.04,
    SOL: 32.65,
    USDT: 99.57
  },
  LAST_12_MONTHS_RETURN: 76.54,
  LAST_24_MONTHS_RETURN: 90.27,
  AVG_ALLOCATION_PER_YEAR: {
    2022: {
      BTC: 5.77,
      ETH: 15.51,
      BNB: 0.64,
      SOL: 4.89,
      USDT: 73.18
    },
    2023: {
      BTC: 9.49,
      ETH: 7.26,
      BNB: 0.66,
      SOL: 11.34,
      USDT: 71.25
    },
    2024: {
      BTC: 10.16,
      ETH: 12.42,
      BNB: 0.79,
      SOL: 8.95,
      USDT: 67.66
    }
  },
  CHARTS: {
    ALL: {
      TIMESTAMP: [
        '2022-01-01',
        '2022-01-02',
        '2022-01-03',
        '2022-01-04',
        '2022-01-05',
        '2022-01-06',
        '2022-01-07',
        '2022-01-08',
        '2022-01-09',
        '2022-01-10',
        '2022-01-11',
        '2022-01-12',
        '2022-01-13',
        '2022-01-14',
        '2022-01-15',
        '2022-01-16',
        '2022-01-17',
        '2022-01-18',
        '2022-01-19',
        '2022-01-20',
        '2022-01-21',
        '2022-01-22',
        '2022-01-23',
        '2022-01-24',
        '2022-01-25',
        '2022-01-26',
        '2022-01-27',
        '2022-01-28',
        '2022-01-29',
        '2022-01-30',
        '2022-01-31',
        '2022-02-01',
        '2022-02-02',
        '2022-02-03',
        '2022-02-04',
        '2022-02-05',
        '2022-02-06',
        '2022-02-07',
        '2022-02-08',
        '2022-02-09',
        '2022-02-10',
        '2022-02-11',
        '2022-02-12',
        '2022-02-13',
        '2022-02-14',
        '2022-02-15',
        '2022-02-16',
        '2022-02-17',
        '2022-02-18',
        '2022-02-19',
        '2022-02-20',
        '2022-02-21',
        '2022-02-22',
        '2022-02-23',
        '2022-02-24',
        '2022-02-25',
        '2022-02-26',
        '2022-02-27',
        '2022-02-28',
        '2022-03-01',
        '2022-03-02',
        '2022-03-03',
        '2022-03-04',
        '2022-03-05',
        '2022-03-06',
        '2022-03-07',
        '2022-03-08',
        '2022-03-09',
        '2022-03-10',
        '2022-03-11',
        '2022-03-12',
        '2022-03-13',
        '2022-03-14',
        '2022-03-15',
        '2022-03-16',
        '2022-03-17',
        '2022-03-18',
        '2022-03-19',
        '2022-03-20',
        '2022-03-21',
        '2022-03-22',
        '2022-03-23',
        '2022-03-24',
        '2022-03-25',
        '2022-03-26',
        '2022-03-27',
        '2022-03-28',
        '2022-03-29',
        '2022-03-30',
        '2022-03-31',
        '2022-04-01',
        '2022-04-02',
        '2022-04-03',
        '2022-04-04',
        '2022-04-05',
        '2022-04-06',
        '2022-04-07',
        '2022-04-08',
        '2022-04-09',
        '2022-04-10',
        '2022-04-11',
        '2022-04-12',
        '2022-04-13',
        '2022-04-14',
        '2022-04-15',
        '2022-04-16',
        '2022-04-17',
        '2022-04-18',
        '2022-04-19',
        '2022-04-20',
        '2022-04-21',
        '2022-04-22',
        '2022-04-23',
        '2022-04-24',
        '2022-04-25',
        '2022-04-26',
        '2022-04-27',
        '2022-04-28',
        '2022-04-29',
        '2022-04-30',
        '2022-05-01',
        '2022-05-02',
        '2022-05-03',
        '2022-05-04',
        '2022-05-05',
        '2022-05-06',
        '2022-05-07',
        '2022-05-08',
        '2022-05-09',
        '2022-05-10',
        '2022-05-11',
        '2022-05-12',
        '2022-05-13',
        '2022-05-14',
        '2022-05-15',
        '2022-05-16',
        '2022-05-17',
        '2022-05-18',
        '2022-05-19',
        '2022-05-20',
        '2022-05-21',
        '2022-05-22',
        '2022-05-23',
        '2022-05-24',
        '2022-05-25',
        '2022-05-26',
        '2022-05-27',
        '2022-05-28',
        '2022-05-29',
        '2022-05-30',
        '2022-05-31',
        '2022-06-01',
        '2022-06-02',
        '2022-06-03',
        '2022-06-04',
        '2022-06-05',
        '2022-06-06',
        '2022-06-07',
        '2022-06-08',
        '2022-06-09',
        '2022-06-10',
        '2022-06-11',
        '2022-06-12',
        '2022-06-13',
        '2022-06-14',
        '2022-06-15',
        '2022-06-16',
        '2022-06-17',
        '2022-06-18',
        '2022-06-19',
        '2022-06-20',
        '2022-06-21',
        '2022-06-22',
        '2022-06-23',
        '2022-06-24',
        '2022-06-25',
        '2022-06-26',
        '2022-06-27',
        '2022-06-28',
        '2022-06-29',
        '2022-06-30',
        '2022-07-01',
        '2022-07-02',
        '2022-07-03',
        '2022-07-04',
        '2022-07-05',
        '2022-07-06',
        '2022-07-07',
        '2022-07-08',
        '2022-07-09',
        '2022-07-10',
        '2022-07-11',
        '2022-07-12',
        '2022-07-13',
        '2022-07-14',
        '2022-07-15',
        '2022-07-16',
        '2022-07-17',
        '2022-07-18',
        '2022-07-19',
        '2022-07-20',
        '2022-07-21',
        '2022-07-22',
        '2022-07-23',
        '2022-07-24',
        '2022-07-25',
        '2022-07-26',
        '2022-07-27',
        '2022-07-28',
        '2022-07-29',
        '2022-07-30',
        '2022-07-31',
        '2022-08-01',
        '2022-08-02',
        '2022-08-03',
        '2022-08-04',
        '2022-08-05',
        '2022-08-06',
        '2022-08-07',
        '2022-08-08',
        '2022-08-09',
        '2022-08-10',
        '2022-08-11',
        '2022-08-12',
        '2022-08-13',
        '2022-08-14',
        '2022-08-15',
        '2022-08-16',
        '2022-08-17',
        '2022-08-18',
        '2022-08-19',
        '2022-08-20',
        '2022-08-21',
        '2022-08-22',
        '2022-08-23',
        '2022-08-24',
        '2022-08-25',
        '2022-08-26',
        '2022-08-27',
        '2022-08-28',
        '2022-08-29',
        '2022-08-30',
        '2022-08-31',
        '2022-09-01',
        '2022-09-02',
        '2022-09-03',
        '2022-09-04',
        '2022-09-05',
        '2022-09-06',
        '2022-09-07',
        '2022-09-08',
        '2022-09-09',
        '2022-09-10',
        '2022-09-11',
        '2022-09-12',
        '2022-09-13',
        '2022-09-14',
        '2022-09-15',
        '2022-09-16',
        '2022-09-17',
        '2022-09-18',
        '2022-09-19',
        '2022-09-20',
        '2022-09-21',
        '2022-09-22',
        '2022-09-23',
        '2022-09-24',
        '2022-09-25',
        '2022-09-26',
        '2022-09-27',
        '2022-09-28',
        '2022-09-29',
        '2022-09-30',
        '2022-10-01',
        '2022-10-02',
        '2022-10-03',
        '2022-10-04',
        '2022-10-05',
        '2022-10-06',
        '2022-10-07',
        '2022-10-08',
        '2022-10-09',
        '2022-10-10',
        '2022-10-11',
        '2022-10-12',
        '2022-10-13',
        '2022-10-14',
        '2022-10-15',
        '2022-10-16',
        '2022-10-17',
        '2022-10-18',
        '2022-10-19',
        '2022-10-20',
        '2022-10-21',
        '2022-10-22',
        '2022-10-23',
        '2022-10-24',
        '2022-10-25',
        '2022-10-26',
        '2022-10-27',
        '2022-10-28',
        '2022-10-29',
        '2022-10-30',
        '2022-10-31',
        '2022-11-01',
        '2022-11-02',
        '2022-11-03',
        '2022-11-04',
        '2022-11-05',
        '2022-11-06',
        '2022-11-07',
        '2022-11-08',
        '2022-11-09',
        '2022-11-10',
        '2022-11-11',
        '2022-11-12',
        '2022-11-13',
        '2022-11-14',
        '2022-11-15',
        '2022-11-16',
        '2022-11-17',
        '2022-11-18',
        '2022-11-19',
        '2022-11-20',
        '2022-11-21',
        '2022-11-22',
        '2022-11-23',
        '2022-11-24',
        '2022-11-25',
        '2022-11-26',
        '2022-11-27',
        '2022-11-28',
        '2022-11-29',
        '2022-11-30',
        '2022-12-01',
        '2022-12-02',
        '2022-12-03',
        '2022-12-04',
        '2022-12-05',
        '2022-12-06',
        '2022-12-07',
        '2022-12-08',
        '2022-12-09',
        '2022-12-10',
        '2022-12-11',
        '2022-12-12',
        '2022-12-13',
        '2022-12-14',
        '2022-12-15',
        '2022-12-16',
        '2022-12-17',
        '2022-12-18',
        '2022-12-19',
        '2022-12-20',
        '2022-12-21',
        '2022-12-22',
        '2022-12-23',
        '2022-12-24',
        '2022-12-25',
        '2022-12-26',
        '2022-12-27',
        '2022-12-28',
        '2022-12-29',
        '2022-12-30',
        '2022-12-31',
        '2023-01-01',
        '2023-01-02',
        '2023-01-03',
        '2023-01-04',
        '2023-01-05',
        '2023-01-06',
        '2023-01-07',
        '2023-01-08',
        '2023-01-09',
        '2023-01-10',
        '2023-01-11',
        '2023-01-12',
        '2023-01-13',
        '2023-01-14',
        '2023-01-15',
        '2023-01-16',
        '2023-01-17',
        '2023-01-18',
        '2023-01-19',
        '2023-01-20',
        '2023-01-21',
        '2023-01-22',
        '2023-01-23',
        '2023-01-24',
        '2023-01-25',
        '2023-01-26',
        '2023-01-27',
        '2023-01-28',
        '2023-01-29',
        '2023-01-30',
        '2023-01-31',
        '2023-02-01',
        '2023-02-02',
        '2023-02-03',
        '2023-02-04',
        '2023-02-05',
        '2023-02-06',
        '2023-02-07',
        '2023-02-08',
        '2023-02-09',
        '2023-02-10',
        '2023-02-11',
        '2023-02-12',
        '2023-02-13',
        '2023-02-14',
        '2023-02-15',
        '2023-02-16',
        '2023-02-17',
        '2023-02-18',
        '2023-02-19',
        '2023-02-20',
        '2023-02-21',
        '2023-02-22',
        '2023-02-23',
        '2023-02-24',
        '2023-02-25',
        '2023-02-26',
        '2023-02-27',
        '2023-02-28',
        '2023-03-01',
        '2023-03-02',
        '2023-03-03',
        '2023-03-04',
        '2023-03-05',
        '2023-03-06',
        '2023-03-07',
        '2023-03-08',
        '2023-03-09',
        '2023-03-10',
        '2023-03-11',
        '2023-03-12',
        '2023-03-13',
        '2023-03-14',
        '2023-03-15',
        '2023-03-16',
        '2023-03-17',
        '2023-03-18',
        '2023-03-19',
        '2023-03-20',
        '2023-03-21',
        '2023-03-22',
        '2023-03-23',
        '2023-03-24',
        '2023-03-25',
        '2023-03-26',
        '2023-03-27',
        '2023-03-28',
        '2023-03-29',
        '2023-03-30',
        '2023-03-31',
        '2023-04-01',
        '2023-04-02',
        '2023-04-03',
        '2023-04-04',
        '2023-04-05',
        '2023-04-06',
        '2023-04-07',
        '2023-04-08',
        '2023-04-09',
        '2023-04-10',
        '2023-04-11',
        '2023-04-12',
        '2023-04-13',
        '2023-04-14',
        '2023-04-15',
        '2023-04-16',
        '2023-04-17',
        '2023-04-18',
        '2023-04-19',
        '2023-04-20',
        '2023-04-21',
        '2023-04-22',
        '2023-04-23',
        '2023-04-24',
        '2023-04-25',
        '2023-04-26',
        '2023-04-27',
        '2023-04-28',
        '2023-04-29',
        '2023-04-30',
        '2023-05-01',
        '2023-05-02',
        '2023-05-03',
        '2023-05-04',
        '2023-05-05',
        '2023-05-06',
        '2023-05-07',
        '2023-05-08',
        '2023-05-09',
        '2023-05-10',
        '2023-05-11',
        '2023-05-12',
        '2023-05-13',
        '2023-05-14',
        '2023-05-15',
        '2023-05-16',
        '2023-05-17',
        '2023-05-18',
        '2023-05-19',
        '2023-05-20',
        '2023-05-21',
        '2023-05-22',
        '2023-05-23',
        '2023-05-24',
        '2023-05-25',
        '2023-05-26',
        '2023-05-27',
        '2023-05-28',
        '2023-05-29',
        '2023-05-30',
        '2023-05-31',
        '2023-06-01',
        '2023-06-02',
        '2023-06-03',
        '2023-06-04',
        '2023-06-05',
        '2023-06-06',
        '2023-06-07',
        '2023-06-08',
        '2023-06-09',
        '2023-06-10',
        '2023-06-11',
        '2023-06-12',
        '2023-06-13',
        '2023-06-14',
        '2023-06-15',
        '2023-06-16',
        '2023-06-17',
        '2023-06-18',
        '2023-06-19',
        '2023-06-20',
        '2023-06-21',
        '2023-06-22',
        '2023-06-23',
        '2023-06-24',
        '2023-06-25',
        '2023-06-26',
        '2023-06-27',
        '2023-06-28',
        '2023-06-29',
        '2023-06-30',
        '2023-07-01',
        '2023-07-02',
        '2023-07-03',
        '2023-07-04',
        '2023-07-05',
        '2023-07-06',
        '2023-07-07',
        '2023-07-08',
        '2023-07-09',
        '2023-07-10',
        '2023-07-11',
        '2023-07-12',
        '2023-07-13',
        '2023-07-14',
        '2023-07-15',
        '2023-07-16',
        '2023-07-17',
        '2023-07-18',
        '2023-07-19',
        '2023-07-20',
        '2023-07-21',
        '2023-07-22',
        '2023-07-23',
        '2023-07-24',
        '2023-07-25',
        '2023-07-26',
        '2023-07-27',
        '2023-07-28',
        '2023-07-29',
        '2023-07-30',
        '2023-07-31',
        '2023-08-01',
        '2023-08-02',
        '2023-08-03',
        '2023-08-04',
        '2023-08-05',
        '2023-08-06',
        '2023-08-07',
        '2023-08-08',
        '2023-08-09',
        '2023-08-10',
        '2023-08-11',
        '2023-08-12',
        '2023-08-13',
        '2023-08-14',
        '2023-08-15',
        '2023-08-16',
        '2023-08-17',
        '2023-08-18',
        '2023-08-19',
        '2023-08-20',
        '2023-08-21',
        '2023-08-22',
        '2023-08-23',
        '2023-08-24',
        '2023-08-25',
        '2023-08-26',
        '2023-08-27',
        '2023-08-28',
        '2023-08-29',
        '2023-08-30',
        '2023-08-31',
        '2023-09-01',
        '2023-09-02',
        '2023-09-03',
        '2023-09-04',
        '2023-09-05',
        '2023-09-06',
        '2023-09-07',
        '2023-09-08',
        '2023-09-09',
        '2023-09-10',
        '2023-09-11',
        '2023-09-12',
        '2023-09-13',
        '2023-09-14',
        '2023-09-15',
        '2023-09-16',
        '2023-09-17',
        '2023-09-18',
        '2023-09-19',
        '2023-09-20',
        '2023-09-21',
        '2023-09-22',
        '2023-09-23',
        '2023-09-24',
        '2023-09-25',
        '2023-09-26',
        '2023-09-27',
        '2023-09-28',
        '2023-09-29',
        '2023-09-30',
        '2023-10-01',
        '2023-10-02',
        '2023-10-03',
        '2023-10-04',
        '2023-10-05',
        '2023-10-06',
        '2023-10-07',
        '2023-10-08',
        '2023-10-09',
        '2023-10-10',
        '2023-10-11',
        '2023-10-12',
        '2023-10-13',
        '2023-10-14',
        '2023-10-15',
        '2023-10-16',
        '2023-10-17',
        '2023-10-18',
        '2023-10-19',
        '2023-10-20',
        '2023-10-21',
        '2023-10-22',
        '2023-10-23',
        '2023-10-24',
        '2023-10-25',
        '2023-10-26',
        '2023-10-27',
        '2023-10-28',
        '2023-10-29',
        '2023-10-30',
        '2023-10-31',
        '2023-11-01',
        '2023-11-02',
        '2023-11-03',
        '2023-11-04',
        '2023-11-05',
        '2023-11-06',
        '2023-11-07',
        '2023-11-08',
        '2023-11-09',
        '2023-11-10',
        '2023-11-11',
        '2023-11-12',
        '2023-11-13',
        '2023-11-14',
        '2023-11-15',
        '2023-11-16',
        '2023-11-17',
        '2023-11-18',
        '2023-11-19',
        '2023-11-20',
        '2023-11-21',
        '2023-11-22',
        '2023-11-23',
        '2023-11-24',
        '2023-11-25',
        '2023-11-26',
        '2023-11-27',
        '2023-11-28',
        '2023-11-29',
        '2023-11-30',
        '2023-12-01',
        '2023-12-02',
        '2023-12-03',
        '2023-12-04',
        '2023-12-05',
        '2023-12-06',
        '2023-12-07',
        '2023-12-08',
        '2023-12-09',
        '2023-12-10',
        '2023-12-11',
        '2023-12-12',
        '2023-12-13',
        '2023-12-14',
        '2023-12-15',
        '2023-12-16',
        '2023-12-17',
        '2023-12-18',
        '2023-12-19',
        '2023-12-20',
        '2023-12-21',
        '2023-12-22',
        '2023-12-23',
        '2023-12-24',
        '2023-12-25',
        '2023-12-26',
        '2023-12-27',
        '2023-12-28',
        '2023-12-29',
        '2023-12-30',
        '2023-12-31',
        '2024-01-01',
        '2024-01-02',
        '2024-01-03',
        '2024-01-04',
        '2024-01-05',
        '2024-01-06',
        '2024-01-07',
        '2024-01-08',
        '2024-01-09',
        '2024-01-10',
        '2024-01-11',
        '2024-01-12',
        '2024-01-13',
        '2024-01-14',
        '2024-01-15',
        '2024-01-16',
        '2024-01-17',
        '2024-01-18',
        '2024-01-19',
        '2024-01-20',
        '2024-01-21',
        '2024-01-22',
        '2024-01-23',
        '2024-01-24',
        '2024-01-25',
        '2024-01-26',
        '2024-01-27',
        '2024-01-28',
        '2024-01-29',
        '2024-01-30',
        '2024-01-31',
        '2024-02-01',
        '2024-02-02',
        '2024-02-03',
        '2024-02-04',
        '2024-02-05',
        '2024-02-06',
        '2024-02-07',
        '2024-02-08',
        '2024-02-09',
        '2024-02-10',
        '2024-02-11',
        '2024-02-12',
        '2024-02-13',
        '2024-02-14',
        '2024-02-15',
        '2024-02-16',
        '2024-02-17',
        '2024-02-18',
        '2024-02-19',
        '2024-02-20',
        '2024-02-21',
        '2024-02-22',
        '2024-02-23',
        '2024-02-24',
        '2024-02-25',
        '2024-02-26',
        '2024-02-27',
        '2024-02-28',
        '2024-02-29',
        '2024-03-01',
        '2024-03-02',
        '2024-03-03',
        '2024-03-04',
        '2024-03-05',
        '2024-03-06',
        '2024-03-07',
        '2024-03-08',
        '2024-03-09',
        '2024-03-10',
        '2024-03-11',
        '2024-03-12',
        '2024-03-13',
        '2024-03-14',
        '2024-03-15',
        '2024-03-16',
        '2024-03-17',
        '2024-03-18',
        '2024-03-19',
        '2024-03-20',
        '2024-03-21',
        '2024-03-22',
        '2024-03-23',
        '2024-03-24',
        '2024-03-25',
        '2024-03-26',
        '2024-03-27',
        '2024-03-28',
        '2024-03-29',
        '2024-03-30',
        '2024-03-31',
        '2024-04-01',
        '2024-04-02',
        '2024-04-03',
        '2024-04-04',
        '2024-04-05',
        '2024-04-06',
        '2024-04-07',
        '2024-04-08',
        '2024-04-09',
        '2024-04-10',
        '2024-04-11',
        '2024-04-12',
        '2024-04-13',
        '2024-04-14',
        '2024-04-15',
        '2024-04-16',
        '2024-04-17',
        '2024-04-18',
        '2024-04-19',
        '2024-04-20',
        '2024-04-21',
        '2024-04-22',
        '2024-04-23',
        '2024-04-24',
        '2024-04-25',
        '2024-04-26',
        '2024-04-27',
        '2024-04-28',
        '2024-04-29',
        '2024-04-30',
        '2024-05-01',
        '2024-05-02',
        '2024-05-03',
        '2024-05-04',
        '2024-05-05',
        '2024-05-06',
        '2024-05-07',
        '2024-05-08',
        '2024-05-09',
        '2024-05-10',
        '2024-05-11',
        '2024-05-12',
        '2024-05-13',
        '2024-05-14',
        '2024-05-15',
        '2024-05-16',
        '2024-05-17',
        '2024-05-18',
        '2024-05-19',
        '2024-05-20',
        '2024-05-21',
        '2024-05-22',
        '2024-05-23',
        '2024-05-24',
        '2024-05-25',
        '2024-05-26',
        '2024-05-27',
        '2024-05-28',
        '2024-05-29',
        '2024-05-30',
        '2024-05-31',
        '2024-06-01',
        '2024-06-02',
        '2024-06-03',
        '2024-06-04',
        '2024-06-05',
        '2024-06-06',
        '2024-06-07',
        '2024-06-08',
        '2024-06-09',
        '2024-06-10',
        '2024-06-11',
        '2024-06-12',
        '2024-06-13',
        '2024-06-14',
        '2024-06-15',
        '2024-06-16',
        '2024-06-17',
        '2024-06-18',
        '2024-06-19',
        '2024-06-20',
        '2024-06-21',
        '2024-06-22',
        '2024-06-23',
        '2024-06-24',
        '2024-06-25',
        '2024-06-26',
        '2024-06-27',
        '2024-06-28',
        '2024-06-29',
        '2024-06-30',
        '2024-07-01',
        '2024-07-02',
        '2024-07-03',
        '2024-07-04',
        '2024-07-05',
        '2024-07-06',
        '2024-07-07',
        '2024-07-08',
        '2024-07-09',
        '2024-07-10',
        '2024-07-11',
        '2024-07-12',
        '2024-07-13',
        '2024-07-14',
        '2024-07-15',
        '2024-07-16',
        '2024-07-17',
        '2024-07-18',
        '2024-07-19',
        '2024-07-20',
        '2024-07-21',
        '2024-07-22',
        '2024-07-23',
        '2024-07-24',
        '2024-07-25',
        '2024-07-26',
        '2024-07-27',
        '2024-07-28',
        '2024-07-29',
        '2024-07-30',
        '2024-07-31'
      ],
      BTC: [
        10000.0, 10264.8, 10224.69, 10000.21, 9987.66, 9398.52, 9343.11,
        8988.44, 9068.35, 9132.01, 9002.11, 9241.87, 9486.34, 9216.19, 9358.16,
        9355.32, 9345.06, 9104.28, 9191.64, 9082.58, 8907.4, 7814.79, 7545.94,
        7683.0, 7869.29, 7960.54, 7899.58, 7941.96, 8147.44, 8252.39, 8175.75,
        8311.34, 8373.02, 8007.23, 7991.26, 8793.69, 8996.67, 8999.03, 9535.25,
        9533.93, 9641.0, 9511.14, 9121.12, 9125.56, 9167.97, 9234.29, 9556.92,
        9522.59, 8808.83, 8641.86, 8658.17, 8398.69, 8123.27, 8204.21, 8145.98,
        8193.98, 8530.24, 8469.88, 8182.82, 9309.67, 9554.24, 9534.9, 9194.05,
        8439.49, 8539.06, 8432.87, 8267.85, 8341.59, 9064.22, 8534.11, 8420.44,
        8460.53, 8161.71, 8509.02, 8539.52, 8845.89, 8868.57, 9019.89, 9077.73,
        8965.54, 8897.17, 9202.07, 9193.31, 9509.28, 9585.12, 9599.11, 10085.7,
        10286.41, 10241.08, 10192.05, 9907.16, 10032.77, 9977.03, 10103.47,
        10097.31, 9921.58, 9414.59, 9440.2, 9152.7, 9205.15, 9111.8, 8604.29,
        8617.61, 8931.59, 8621.02, 8755.98, 8742.65, 8654.84, 8854.71, 8956.76,
        8972.4, 8743.98, 8592.35, 8615.1, 8557.11, 8729.91, 8269.92, 8454.55,
        8610.46, 8353.79, 8276.28, 8288.44, 8349.98, 8165.77, 8585.99, 7840.99,
        7789.19, 7582.95, 7416.17, 6726.51, 6661.01, 6245.13, 6236.14, 6449.91,
        6542.91, 6727.12, 6495.12, 6567.17, 6269.59, 6531.2, 6307.85, 6364.39,
        6550.76, 6320.46, 6405.79, 6444.72, 6384.86, 6236.45, 6273.17, 6367.11,
        6873.77, 6895.59, 6436.42, 6595.62, 6432.79, 6449.59, 6496.04, 6793.08,
        6763.75, 6558.32, 6505.76, 6313.3, 6195.95, 5841.03, 4887.36, 4673.38,
        4862.81, 4406.4, 4425.04, 4151.49, 4477.84, 4433.36, 4521.5, 4349.77,
        4542.01, 4611.08, 4637.12, 4574.9, 4503.63, 4394.06, 4373.64, 4088.23,
        4176.09, 4182.74, 4175.95, 4362.83, 4421.59, 4438.58, 4679.7, 4711.05,
        4679.56, 4509.13, 4328.99, 4192.26, 4294.31, 4435.94, 4520.16, 4607.98,
        4544.26, 4715.27, 5085.1, 5043.4, 4980.23, 4917.31, 4861.65, 4902.36,
        4686.24, 4567.66, 4949.14, 5184.66, 5180.54, 5128.2, 5058.2, 5012.19,
        4998.37, 4961.86, 4894.86, 5029.2, 5014.1, 5029.46, 5152.53, 5013.39,
        5158.88, 5169.81, 5244.66, 5276.65, 5248.87, 5195.87, 5160.0, 5051.58,
        5051.05, 4538.41, 4573.29, 4666.25, 4581.86, 4646.67, 4650.62, 4668.48,
        4459.95, 4331.76, 4306.62, 4371.53, 4307.31, 4360.96, 4344.37, 4325.15,
        4282.78, 4291.44, 4285.13, 4091.39, 4184.01, 4177.29, 4618.89, 4707.8,
        4681.38, 4830.16, 4375.92, 4391.49, 4268.8, 4268.75, 4354.73, 4207.74,
        4241.55, 4102.52, 4000.46, 4197.07, 4183.13, 4086.45, 4065.26, 4140.99,
        4128.4, 4216.14, 4209.73, 4194.32, 4181.21, 4147.97, 4223.34, 4392.25,
        4368.62, 4306.07, 4229.98, 4189.1, 4197.13, 4139.24, 4119.73, 4144.67,
        4204.31, 4145.63, 4124.27, 4176.41, 4222.31, 4180.4, 4141.42, 4118.41,
        4152.02, 4152.66, 4239.34, 4189.19, 4341.12, 4504.21, 4385.75, 4463.73,
        4491.38, 4454.35, 4431.17, 4430.95, 4351.18, 4369.7, 4582.31, 4608.04,
        4573.91, 4427.26, 3994.14, 3434.81, 3856.43, 3661.2, 3642.75, 3556.78,
        3550.76, 3652.44, 3606.82, 3615.76, 3605.98, 3621.66, 3526.18, 3405.73,
        3501.94, 3572.83, 3594.46, 3576.43, 3553.96, 3585.92, 3509.7, 3569.17,
        3710.39, 3670.79, 3687.38, 3656.95, 3700.7, 3666.19, 3672.65, 3644.09,
        3721.98, 3707.07, 3708.22, 3700.25, 3712.49, 3846.4, 3859.41, 3751.85,
        3618.48, 3621.91, 3626.74, 3552.12, 3650.02, 3627.44, 3634.69, 3633.5,
        3640.63, 3639.75, 3643.41, 3611.44, 3574.03, 3595.99, 3588.14, 3574.62,
        3592.22, 3613.05, 3608.44, 3640.8, 3643.22, 3669.29, 3664.53, 3670.33,
        3722.62, 3770.93, 3798.69, 4092.42, 4294.83, 4541.61, 4524.59, 4584.01,
        4594.07, 4501.77, 4557.9, 4888.92, 4924.54, 4906.53, 4960.38, 4923.64,
        4961.86, 4977.35, 4978.31, 4971.69, 5142.25, 4918.07, 5015.87, 5131.13,
        5096.87, 5067.62, 5063.7, 4972.1, 4950.43, 5026.01, 4961.11, 4709.86,
        4667.38, 4722.19, 4715.7, 4682.35, 4800.77, 5225.7, 5191.77, 5339.85,
        5328.63, 5299.17, 5345.34, 5276.01, 5214.69, 5163.93, 5002.47, 5002.22,
        5079.62, 5080.85, 5011.44, 5091.63, 5076.27, 4829.61, 4826.37, 4853.85,
        4853.99, 4783.02, 4717.35, 4401.76, 4348.46, 4399.59, 4695.77, 5236.42,
        5344.01, 5271.04, 5405.29, 5953.08, 5822.64, 6082.81, 6048.04, 6065.13,
        5887.28, 6104.71, 5906.5, 5938.8, 6067.1, 5863.65, 5889.26, 6142.65,
        6036.44, 6181.78, 6170.45, 6081.28, 6015.11, 6098.9, 6096.31, 6060.09,
        6048.86, 6044.92, 6138.74, 6403.28, 6548.2, 6456.8, 6555.19, 6585.32,
        6567.07, 6562.75, 6383.44, 6571.37, 6291.24, 6112.28, 5899.58, 6026.64,
        5970.05, 5942.39, 6108.85, 6122.92, 6358.1, 6349.48, 6318.17, 6356.7,
        6062.71, 6217.83, 6297.93, 6227.13, 6402.35, 6239.08, 6218.33, 5983.32,
        5967.42, 5987.06, 5821.21, 5773.8, 5818.2, 5820.54, 5909.13, 5841.66,
        5914.23, 5818.59, 5814.14, 5856.58, 5788.96, 5814.33, 5884.19, 5701.06,
        5723.97, 5774.07, 5799.45, 6077.95, 5991.14, 6001.81, 5881.83, 5816.26,
        5898.9, 5857.49, 5892.66, 5563.05, 5875.04, 5685.34, 5743.43, 5732.25,
        5598.63, 5603.1, 5610.48, 5591.02, 5416.84, 5545.44, 5705.81, 5749.0,
        5712.68, 5789.0, 6104.57, 6515.91, 6475.5, 6630.51, 6602.63, 6599.5,
        6540.36, 6618.01, 6514.05, 6579.69, 6594.27, 6616.11, 6632.05, 6733.62,
        6668.55, 6585.66, 6527.32, 6556.92, 6539.7, 6528.78, 6566.28, 6617.61,
        6570.9, 6774.4, 6540.37, 6552.89, 6560.59, 6514.23, 6452.11, 6463.6,
        6450.88, 6474.91, 6449.59, 6494.06, 6309.65, 6319.43, 6377.75, 6318.53,
        6337.94, 6351.0, 6308.51, 6320.19, 6329.72, 6315.0, 6325.66, 6287.22,
        6294.36, 6296.4, 6313.96, 6440.93, 6405.0, 6375.37, 6368.78, 6367.04,
        6343.87, 6369.53, 6314.48, 6255.29, 5799.02, 5641.22, 5637.33, 5670.97,
        5654.2, 5584.1, 5724.31, 5651.55, 5637.33, 5632.18, 5644.96, 5638.93,
        5980.12, 5894.16, 5637.32, 5574.65, 5595.68, 5614.95, 5576.96, 5575.51,
        5571.17, 5695.75, 5605.24, 5605.85, 5593.7, 5441.44, 5619.16, 5678.04,
        5755.72, 5793.62, 5746.82, 5732.1, 5814.35, 5883.0, 5862.77, 5753.31,
        5752.09, 5747.29, 5731.85, 5686.63, 5659.75, 5697.94, 5844.39, 5817.61,
        5844.24, 6057.62, 5962.0, 5929.47, 6004.89, 5936.19, 6052.16, 6047.79,
        6040.86, 5968.96, 5938.93, 5795.31, 5789.02, 5805.21, 5809.44, 5864.12,
        6168.65, 6148.6, 6132.73, 6198.95, 6417.92, 6490.41, 6499.56, 7208.6,
        7390.29, 7487.84, 7405.89, 7314.75, 7379.2, 7508.6, 7458.0, 7477.59,
        7633.62, 7527.28, 7492.53, 7607.6, 7566.79, 7598.02, 7634.01, 7742.54,
        7908.06, 8081.82, 7982.33, 8039.4, 7890.46, 7704.53, 8158.62, 7836.43,
        7907.23, 7898.83, 8001.46, 8097.85, 7871.05, 8102.2, 8071.71, 8153.32,
        8162.6, 8147.07, 8023.73, 8192.04, 8176.8, 8155.89, 8374.38, 8513.98,
        8667.97, 9076.89, 9582.07, 9469.87, 9333.38, 9576.69, 9498.72, 9473.47,
        8933.15, 8921.01, 9295.81, 9319.32, 9100.81, 9154.93, 9026.53, 9198.16,
        9159.77, 9436.29, 9509.38, 9477.05, 9465.33, 9299.04, 9451.11, 9189.27,
        9374.01, 9223.04, 9035.87, 9116.29, 9143.38, 9418.61, 9732.73, 9249.79,
        9589.13, 9513.27, 9494.06, 9490.64, 10169.43, 10008.02, 10148.38,
        10039.61, 9197.92, 9301.93, 9146.22, 9223.46, 9348.52, 9243.83, 8947.95,
        9019.87, 9040.31, 9008.25, 8618.66, 8539.88, 8628.01, 8645.38, 9067.3,
        9121.23, 9051.66, 9327.99, 9374.87, 9229.53, 9288.18, 9347.78, 9310.73,
        9211.26, 9196.79, 9347.22, 9555.08, 9795.66, 10228.42, 10348.13,
        10415.23, 10823.12, 10715.02, 11206.38, 11174.35, 11246.5, 11188.65,
        11303.17, 11237.53, 11287.78, 11145.51, 11133.59, 10989.05, 11155.35,
        11186.81, 11826.79, 12309.97, 13258.11, 13247.1, 13521.04, 13346.85,
        13594.03, 14697.88, 13717.48, 14262.42, 14518.96, 14735.31, 14792.2,
        14803.79, 15674.15, 15397.39, 15784.65, 15479.0, 14952.16, 14358.61,
        14762.55, 14646.58, 13552.17, 14644.4, 14148.96, 13636.12, 14009.55,
        14486.3, 15213.04, 15199.89, 15024.83, 15329.45, 15104.42, 15085.58,
        15355.85, 15083.6, 14240.66, 14310.77, 14699.6, 14632.84, 14941.71,
        14972.87, 15560.55, 15000.05, 15260.21, 15178.69, 14508.39, 13911.23,
        14135.58, 13730.89, 13800.7, 13347.91, 13739.5, 13850.44, 13980.49,
        14070.69, 14502.9, 14358.99, 13868.51, 13978.2, 13809.21, 13687.63,
        13664.05, 13823.94, 13109.87, 12523.67, 12836.86, 13584.29, 13764.21,
        13817.45, 13738.62, 13599.12, 13252.94, 13692.06, 13167.04, 13179.15,
        13269.16, 13581.61, 13351.99, 14305.65, 14154.76, 14447.43, 14482.14,
        14359.15, 15057.42, 15194.04, 14960.16, 14645.38, 14883.73, 14957.51,
        14834.39, 15057.6, 14846.38, 14643.99, 14781.36, 14607.1, 14662.47,
        14697.64, 14938.79, 15267.41, 15393.67, 15340.91, 15037.57, 15002.76,
        15066.85, 15036.91, 14574.79, 14788.05, 14474.35, 14283.51, 14323.75,
        14444.06, 14422.42, 14124.69, 14028.63, 14061.93, 13881.28, 13916.06,
        13773.74, 12992.85, 13395.52, 13182.31, 13337.11, 13061.88, 13192.14,
        13609.95, 13625.98, 13434.47, 13050.63, 12557.92, 12251.16, 12572.55,
        12202.35, 12264.87, 12535.66, 12486.92, 12406.4, 12504.51, 12866.4,
        13168.64, 13922.09, 13981.22, 13905.98, 13854.67, 14436.53, 14521.51,
        14715.82, 14634.25, 14262.74, 14126.43, 14203.45, 14697.21, 14847.37,
        14715.43, 14546.05, 14328.52
      ],
      ETH: [
        10000.0, 10222.13, 10412.02, 10206.08, 10381.89, 9613.79, 9328.99,
        8654.98, 8482.74, 8648.54, 8331.17, 8792.1, 9147.69, 8832.72, 9013.28,
        9078.87, 9108.21, 8684.36, 8648.02, 8506.16, 8326.52, 6924.57, 6472.31,
        6652.19, 6562.41, 6630.65, 6632.29, 6480.53, 6842.15, 7072.71, 7006.31,
        7290.08, 7584.0, 7287.52, 7236.27, 8040.33, 8213.04, 8131.16, 8573.16,
        8470.42, 8843.35, 8475.59, 7872.17, 7930.95, 7894.34, 8000.62, 8502.46,
        8531.21, 7886.81, 7547.27, 7461.67, 7214.46, 7122.62, 7084.44, 7172.89,
        6980.58, 7593.6, 7603.91, 7154.31, 7881.01, 8090.9, 8053.9, 7708.99,
        7104.32, 7263.04, 7151.6, 6811.92, 6986.48, 7389.86, 7106.23, 6992.0,
        7037.89, 6813.58, 6992.95, 7131.6, 7472.14, 7669.03, 7974.72, 7985.36,
        7825.41, 7910.53, 8159.99, 8172.94, 8460.84, 8429.62, 8525.8, 8894.5,
        9169.89, 9211.28, 9220.38, 8948.66, 9388.18, 9430.2, 9641.62, 9594.54,
        9368.27, 8673.78, 8802.61, 8679.33, 8833.67, 8765.91, 8161.05, 8198.1,
        8494.87, 8200.45, 8250.27, 8326.44, 8284.6, 8338.06, 8427.84, 8393.2,
        8115.13, 8047.73, 8039.4, 7967.38, 8185.65, 7675.36, 7820.73, 7966.48,
        7668.89, 7565.39, 7635.49, 7784.91, 7575.43, 7957.34, 7444.66, 7331.75,
        7080.49, 6907.78, 6238.37, 6311.25, 5668.11, 5234.12, 5600.33, 5592.25,
        5794.98, 5531.0, 5662.25, 5299.21, 5458.12, 5313.52, 5371.78, 5536.71,
        5380.78, 5393.79, 5345.59, 4952.42, 4738.93, 4853.72, 4925.32, 5438.78,
        5323.63, 4946.4, 4989.22, 4849.95, 4893.23, 4938.7, 5046.75, 4920.18,
        4896.57, 4865.94, 4557.2, 4211.19, 3961.7, 3246.78, 3201.13, 3312.17,
        2902.21, 2949.36, 2695.75, 3105.25, 3012.57, 3094.94, 2888.61, 3105.27,
        3346.63, 3379.55, 3302.92, 3270.45, 3123.44, 3016.87, 2784.76, 2896.12,
        2912.04, 2926.64, 3109.0, 3132.72, 3238.65, 3345.35, 3349.68, 3308.53,
        3178.91, 2988.72, 2837.07, 2955.09, 3229.73, 3397.64, 3694.22, 3700.26,
        4119.47, 4252.37, 4152.0, 4286.95, 4188.15, 4194.11, 4366.86, 4031.39,
        3838.12, 4422.74, 4748.72, 4738.14, 4621.39, 4582.66, 4430.35, 4481.11,
        4412.35, 4343.74, 4666.28, 4660.97, 4637.12, 4837.29, 4634.64, 5032.3,
        5095.14, 5254.43, 5382.34, 5257.42, 5155.42, 5091.82, 4975.53, 5078.3,
        4406.82, 4280.23, 4402.88, 4318.93, 4499.42, 4517.4, 4608.94, 4213.5,
        4037.37, 3983.59, 4191.14, 4204.34, 4266.79, 4315.64, 4299.3, 4230.34,
        4261.6, 4369.48, 4295.63, 4448.52, 4439.66, 4677.29, 4851.16, 4749.07,
        4640.82, 4317.77, 4458.1, 4012.37, 3896.03, 3985.98, 3647.18, 3769.54,
        3620.89, 3383.6, 3600.26, 3617.24, 3575.65, 3511.59, 3610.88, 3617.87,
        3639.71, 3615.88, 3597.41, 3571.02, 3509.03, 3578.51, 3696.92, 3687.09,
        3674.53, 3627.33, 3568.41, 3581.53, 3515.07, 3484.28, 3521.3, 3515.64,
        3524.75, 3457.35, 3558.86, 3620.86, 3570.5, 3499.16, 3489.89, 3540.04,
        3577.71, 3714.43, 3662.28, 3966.21, 4266.38, 4127.6, 4239.73, 4382.21,
        4317.44, 4259.34, 4293.83, 4111.55, 4165.16, 4487.37, 4440.2, 4357.23,
        4241.36, 3586.78, 3040.2, 3601.89, 3482.89, 3429.27, 3341.17, 3318.65,
        3388.99, 3307.65, 3282.41, 3291.49, 3330.02, 3108.81, 3013.88, 3094.59,
        3194.52, 3273.65, 3262.2, 3267.81, 3308.42, 3184.82, 3327.25, 3533.27,
        3475.03, 3510.61, 3378.49, 3469.83, 3424.97, 3408.06, 3346.88, 3493.53,
        3438.66, 3452.24, 3433.71, 3461.29, 3590.17, 3568.31, 3436.65, 3206.33,
        3225.69, 3230.41, 3162.27, 3306.35, 3290.51, 3307.55, 3314.35, 3314.73,
        3311.3, 3314.73, 3294.68, 3225.34, 3258.26, 3259.32, 3253.85, 3267.01,
        3305.31, 3298.73, 3409.23, 3402.27, 3453.75, 3435.88, 3453.24, 3593.14,
        3629.97, 3670.4, 3869.56, 3953.38, 4219.16, 4236.81, 4297.89, 4282.03,
        4165.63, 4220.36, 4493.58, 4431.07, 4430.38, 4427.01, 4249.36, 4314.07,
        4357.13, 4333.08, 4256.27, 4469.31, 4254.25, 4325.96, 4455.35, 4503.64,
        4535.11, 4569.91, 4445.5, 4441.65, 4529.94, 4490.74, 4183.61, 4103.41,
        4178.96, 4116.61, 4057.42, 4228.62, 4531.21, 4477.65, 4620.85, 4597.03,
        4585.0, 4605.05, 4496.83, 4446.33, 4480.38, 4365.56, 4329.74, 4443.52,
        4436.86, 4386.16, 4506.05, 4484.98, 4262.43, 4247.7, 4258.68, 4268.99,
        4225.2, 4204.29, 3896.76, 3873.94, 3970.62, 4255.21, 4570.72, 4628.12,
        4483.65, 4550.01, 4820.67, 4812.48, 4909.87, 4753.51, 4879.35, 4696.99,
        4919.44, 4741.98, 4727.2, 4831.25, 4650.12, 4816.02, 4870.54, 4854.08,
        4967.98, 4959.64, 4878.4, 4913.49, 5078.55, 5195.41, 5085.18, 5080.83,
        5034.91, 5075.33, 5179.08, 5152.32, 5203.73, 5460.43, 5725.35, 5699.48,
        5784.3, 5657.13, 5691.98, 5347.87, 5285.31, 5019.0, 5106.95, 5064.21,
        5002.6, 5080.23, 5073.14, 5182.84, 5152.24, 5177.94, 5147.64, 4967.02,
        5097.42, 5186.97, 5090.24, 5409.3, 5155.78, 5188.71, 5024.38, 5015.9,
        5011.71, 4868.25, 4902.99, 4901.3, 4896.38, 4959.38, 4959.97, 4951.59,
        4920.15, 4929.88, 4946.21, 4909.9, 4947.02, 5044.29, 4894.64, 4916.13,
        4967.83, 4978.11, 5186.62, 5147.21, 5180.31, 5085.16, 5079.8, 5185.15,
        5144.87, 5172.72, 4915.83, 5121.07, 4977.16, 5030.89, 5001.0, 4770.89,
        4770.81, 4741.27, 4719.18, 4488.27, 4530.46, 4679.3, 4707.35, 4685.29,
        4714.96, 4855.9, 5149.9, 5088.12, 5142.11, 5100.18, 5168.77, 5056.76,
        5130.31, 4971.95, 5036.65, 5262.43, 5230.2, 5282.73, 5318.87, 5281.42,
        5192.25, 5082.92, 5080.83, 5062.06, 5077.81, 5103.85, 5111.24, 5090.63,
        5416.47, 5240.18, 5246.14, 5259.16, 5194.97, 5171.87, 5136.13, 5153.32,
        5149.52, 5125.25, 5123.51, 5026.21, 5058.77, 5093.88, 5050.04, 5103.13,
        5113.26, 5048.21, 5049.74, 5035.92, 5012.2, 4999.17, 4974.12, 5000.61,
        4978.31, 4971.78, 5052.46, 5047.48, 5036.71, 5023.13, 5031.4, 5002.76,
        5020.79, 4973.49, 4929.53, 4611.54, 4521.98, 4533.02, 4580.27, 4534.81,
        4405.79, 4559.53, 4500.45, 4499.06, 4484.35, 4508.51, 4490.94, 4705.74,
        4628.33, 4487.37, 4424.89, 4452.44, 4449.62, 4418.44, 4438.65, 4438.29,
        4489.66, 4449.94, 4448.82, 4405.22, 4210.62, 4344.34, 4370.9, 4433.92,
        4486.97, 4450.18, 4408.56, 4466.72, 4466.54, 4414.82, 4313.8, 4338.03,
        4326.58, 4323.18, 4316.87, 4319.4, 4347.47, 4489.03, 4534.65, 4556.14,
        4718.28, 4519.04, 4508.89, 4484.43, 4395.16, 4474.58, 4447.96, 4437.02,
        4289.7, 4265.62, 4258.2, 4188.86, 4214.87, 4233.63, 4230.36, 4357.59,
        4249.43, 4256.02, 4254.96, 4374.87, 4435.14, 4533.42, 4856.53, 4878.91,
        4876.68, 4916.15, 4833.76, 4833.41, 4910.3, 4921.73, 4920.86, 5014.27,
        4885.22, 4981.2, 5053.58, 5129.09, 5176.74, 5112.6, 5144.1, 5696.3,
        5662.6, 5562.77, 5605.77, 5599.95, 5404.45, 5584.01, 5352.96, 5338.57,
        5323.9, 5388.21, 5505.81, 5283.73, 5616.79, 5619.65, 5641.46, 5665.8,
        5649.08, 5495.63, 5573.95, 5519.35, 5558.25, 5672.96, 5869.85, 5997.91,
        6060.75, 6206.55, 6076.31, 6391.93, 6430.09, 6401.69, 6423.4, 6047.75,
        5952.07, 6149.49, 6297.08, 6068.72, 6063.11, 6035.75, 6019.02, 5924.06,
        5962.98, 6106.12, 6291.75, 6246.7, 6146.52, 6215.5, 6060.42, 6432.49,
        6412.01, 6206.42, 6223.28, 6187.78, 6349.46, 6416.99, 5999.06, 6174.8,
        6114.28, 6086.26, 6028.98, 6358.77, 6367.67, 6929.92, 7114.41, 6796.64,
        7013.08, 6824.49, 6838.48, 7054.92, 6878.27, 6720.28, 6775.7, 6723.87,
        6697.56, 6312.5, 6056.24, 6042.55, 6042.63, 6169.39, 6165.18, 6118.75,
        6287.5, 6433.12, 6217.65, 6248.39, 6280.59, 6252.06, 6224.21, 6241.81,
        6478.79, 6597.93, 6594.48, 6787.44, 6789.54, 6801.48, 7222.27, 7170.07,
        7540.39, 7652.97, 7586.69, 7577.14, 7834.11, 8055.84, 8147.07, 7981.54,
        8127.59, 7950.83, 8128.9, 8439.08, 8663.72, 8834.19, 9086.29, 9038.09,
        9338.94, 9259.49, 9449.42, 9815.72, 9538.34, 10373.19, 10491.76,
        10545.81, 10611.63, 10401.14, 11065.12, 10744.14, 10834.77, 10547.79,
        10145.72, 9678.37, 9916.38, 9581.32, 8735.28, 9576.28, 9474.93, 8941.77,
        9186.32, 9361.6, 9824.6, 9806.3, 9561.84, 9702.04, 9580.17, 9538.04,
        9873.7, 9532.08, 8972.08, 9027.08, 9009.99, 9016.42, 9189.85, 9305.73,
        10122.43, 9571.49, 9624.56, 9535.94, 8756.12, 8211.71, 8586.76, 8505.53,
        8415.15, 8183.9, 8351.9, 8350.51, 8544.08, 8583.82, 8755.68, 8746.49,
        8520.15, 8595.22, 8520.69, 8827.49, 8951.05, 8746.49, 8188.04, 8077.73,
        8153.5, 8448.88, 8452.69, 8519.57, 8383.02, 8260.2, 8076.7, 8308.35,
        7903.65, 7932.02, 7975.48, 7998.06, 7860.2, 8196.85, 7996.07, 8408.04,
        8481.3, 8370.13, 9825.17, 10374.22, 10186.93, 10366.6, 10166.34,
        10167.1, 10440.21, 10575.67, 10508.05, 10280.48, 10193.71, 10256.11,
        10396.14, 10288.53, 10278.23, 10375.82, 10474.75, 10369.13, 10051.22,
        10001.26, 10085.71, 9995.65, 9522.26, 9715.12, 9471.09, 9479.88,
        9712.75, 9870.47, 9585.93, 9467.01, 9675.7, 9573.75, 9584.74, 9527.43,
        9317.73, 9065.46, 9257.15, 9195.13, 9388.04, 9178.76, 9185.14, 9386.39,
        9367.04, 9315.77, 8982.01, 8508.97, 8098.89, 8335.63, 7990.79, 8227.7,
        8322.98, 8435.11, 8440.66, 8506.76, 8682.59, 8829.4, 9446.48, 9326.59,
        9235.49, 9339.97, 9543.32, 9559.25, 9611.73, 9379.67, 9472.72, 9056.16,
        8609.48, 8921.26, 8909.1, 8867.05, 9056.53, 8919.58
      ],
      SOL: [
        10000.0, 10427.68, 10402.38, 10005.89, 9937.06, 8917.0, 8940.53,
        8045.77, 8486.39, 8319.9, 7936.95, 8250.49, 8948.76, 8628.16, 8644.04,
        8735.22, 8695.81, 8183.43, 8286.96, 8061.06, 7768.11, 6486.27, 5483.26,
        5653.87, 5419.74, 5500.91, 5393.85, 5196.78, 5315.61, 5611.51, 5466.79,
        5875.65, 6465.09, 5952.71, 5917.99, 6470.39, 6749.81, 6635.1, 6946.88,
        6652.74, 6763.34, 6359.79, 5622.68, 5655.63, 5514.45, 5696.81, 6038.59,
        6033.89, 5533.86, 5285.6, 5333.26, 5470.91, 5015.0, 5005.59, 5093.24,
        5129.13, 5573.27, 5336.79, 5029.12, 5787.4, 5759.16, 5945.65, 5631.51,
        5203.24, 5297.96, 5043.24, 4838.52, 4809.1, 5169.13, 4906.76, 4775.58,
        4815.58, 4605.57, 4752.63, 4900.88, 5055.59, 5205.01, 5229.13, 5438.56,
        5260.9, 5219.14, 5382.67, 5549.74, 6022.71, 5766.22, 5962.7, 6203.31,
        6442.74, 6517.44, 7066.3, 7348.08, 8034.01, 7896.35, 8092.25, 7771.64,
        7567.51, 6775.69, 6973.35, 6502.74, 6525.1, 6556.27, 5879.17, 6001.53,
        6194.48, 5914.47, 5943.88, 6005.06, 5999.18, 6026.24, 6345.08, 6263.31,
        5971.53, 5927.41, 5965.65, 5854.47, 5923.88, 5668.57, 5762.1, 5760.34,
        5533.86, 5021.48, 5241.49, 5151.48, 5049.12, 5417.97, 4934.41, 4829.69,
        4579.68, 4461.44, 3858.46, 3832.0, 2810.75, 2543.1, 2949.0, 3073.12,
        3396.09, 3197.25, 3326.67, 2993.71, 3021.95, 2925.46, 2957.82, 3089.01,
        2899.0, 2919.0, 2864.28, 2588.97, 2431.91, 2588.97, 2638.39, 2793.69,
        2710.16, 2373.08, 2410.73, 2253.66, 2254.25, 2303.67, 2501.33, 2323.67,
        2294.84, 2350.13, 2217.19, 2036.0, 1841.87, 1598.91, 1678.33, 2007.77,
        1780.69, 1819.52, 1863.05, 2040.71, 2050.71, 2156.01, 2051.88, 2220.13,
        2484.27, 2471.91, 2338.96, 2276.6, 2076.0, 2008.36, 1911.29, 1937.17,
        1970.12, 1958.94, 2146.01, 2117.77, 2165.43, 2253.07, 2266.02, 2246.61,
        2163.66, 1972.46, 1942.47, 2008.36, 2161.89, 2224.25, 2341.91, 2304.85,
        2412.49, 2663.1, 2496.03, 2516.62, 2414.85, 2350.72, 2416.61, 2223.66,
        2113.65, 2344.85, 2547.2, 2514.85, 2597.8, 2501.33, 2433.67, 2422.5,
        2281.9, 2268.96, 2377.2, 2379.55, 2397.79, 2483.68, 2374.84, 2468.38,
        2508.38, 2633.68, 2736.05, 2654.27, 2546.04, 2533.09, 2374.26, 2394.85,
        2129.54, 2064.24, 2151.89, 2050.13, 2086.6, 2083.65, 2075.41, 1900.11,
        1847.17, 1841.28, 1886.58, 1869.53, 1878.93, 1850.11, 1833.63, 1829.52,
        1875.99, 1894.23, 1832.47, 1924.82, 1972.46, 2041.3, 2076.59, 2048.95,
        2193.66, 1964.24, 2006.0, 1943.65, 1887.76, 1976.58, 1840.11, 1932.46,
        1865.99, 1802.46, 1904.82, 1985.41, 1969.53, 1899.52, 1966.59, 1931.88,
        1956.0, 1988.36, 1930.71, 1915.4, 1895.4, 1933.05, 2000.71, 2003.65,
        1961.88, 1947.18, 1911.88, 1924.82, 1886.58, 1840.7, 1831.29, 1801.87,
        1761.28, 1744.81, 1780.69, 1824.23, 1775.4, 1716.57, 1661.27, 1658.33,
        1647.75, 1717.16, 1673.04, 1823.05, 1844.23, 1804.22, 1891.88, 1926.0,
        1935.41, 1918.35, 1904.82, 1797.75, 1817.75, 1993.05, 2173.65, 1954.82,
        1730.69, 1383.02, 836.52, 1057.71, 940.06, 877.11, 804.75, 807.69,
        835.93, 842.4, 805.93, 774.75, 757.69, 724.74, 694.16, 721.81, 830.05,
        858.29, 831.81, 827.11, 847.11, 785.34, 789.46, 817.11, 794.75, 807.1,
        783.57, 802.98, 815.34, 828.87, 797.11, 804.16, 797.7, 798.87, 792.99,
        782.4, 810.64, 835.93, 823.57, 734.16, 731.22, 731.81, 693.57, 722.4,
        707.69, 693.57, 692.39, 671.8, 668.86, 657.69, 649.45, 571.8, 538.86,
        577.68, 585.32, 586.5, 663.57, 786.51, 797.11, 790.05, 786.51, 767.1,
        806.52, 959.47, 958.3, 952.41, 964.17, 1063.0, 1389.5, 1361.84, 1391.26,
        1364.2, 1244.19, 1260.66, 1483.62, 1459.49, 1424.2, 1434.21, 1352.44,
        1423.61, 1430.09, 1418.31, 1403.61, 1545.39, 1397.72, 1414.21, 1467.15,
        1445.97, 1453.03, 1448.32, 1387.14, 1353.02, 1391.26, 1361.84, 1192.43,
        1176.54, 1222.42, 1269.49, 1202.42, 1276.55, 1398.31, 1318.31, 1363.61,
        1387.73, 1482.44, 1543.03, 1461.85, 1418.31, 1401.85, 1341.84, 1306.55,
        1356.56, 1335.38, 1302.43, 1318.31, 1297.73, 1257.73, 1218.89, 1238.31,
        1218.31, 1179.48, 1080.65, 1007.71, 1064.18, 1055.95, 1166.54, 1208.31,
        1228.9, 1131.83, 1154.78, 1246.54, 1266.54, 1294.2, 1318.9, 1321.84,
        1261.25, 1296.55, 1208.9, 1196.55, 1237.14, 1165.36, 1202.42, 1242.42,
        1201.83, 1258.89, 1245.37, 1208.9, 1203.01, 1232.43, 1230.66, 1211.25,
        1205.36, 1173.01, 1197.72, 1225.95, 1364.79, 1393.03, 1434.79, 1453.62,
        1418.9, 1495.39, 1458.32, 1455.38, 1345.96, 1307.72, 1249.49, 1285.96,
        1263.6, 1256.55, 1276.55, 1247.72, 1308.9, 1368.32, 1355.97, 1361.25,
        1292.43, 1309.49, 1304.19, 1274.2, 1344.2, 1288.9, 1303.6, 1214.78,
        1212.43, 1237.73, 1186.54, 1218.31, 1229.49, 1232.43, 1241.83, 1214.78,
        1234.19, 1203.01, 1197.72, 1190.66, 1156.54, 1153.01, 1178.9, 1131.83,
        1131.24, 1140.06, 1198.31, 1232.43, 1210.66, 1249.49, 1221.83, 1209.48,
        1254.19, 1243.6, 1299.48, 1175.96, 1199.48, 1093.01, 1113.6, 1026.53,
        925.93, 912.41, 894.17, 877.7, 847.69, 868.87, 902.99, 925.35, 904.76,
        939.47, 971.82, 1015.36, 977.71, 1002.41, 973.59, 1001.23, 957.71,
        980.06, 941.24, 1053.0, 1125.95, 1084.77, 1147.71, 1121.83, 1144.77,
        1107.71, 1194.19, 1264.78, 1288.9, 1258.89, 1251.25, 1294.79, 1293.02,
        1516.56, 1548.92, 1608.33, 1623.62, 1566.56, 1500.09, 1552.45, 1500.67,
        1514.8, 1481.26, 1451.85, 1377.15, 1362.43, 1490.09, 1478.32, 1464.79,
        1485.97, 1417.73, 1398.9, 1392.44, 1365.96, 1336.55, 1341.84, 1333.02,
        1367.73, 1355.38, 1417.73, 1432.44, 1447.73, 1445.38, 1470.09, 1428.91,
        1475.39, 1402.43, 1343.61, 1294.2, 1258.89, 1281.84, 1282.43, 1250.07,
        1196.55, 1266.54, 1231.84, 1197.72, 1195.37, 1223.6, 1207.13, 1285.37,
        1225.95, 1164.18, 1133.01, 1147.13, 1147.71, 1137.72, 1189.48, 1152.42,
        1172.42, 1153.01, 1146.54, 1078.89, 1033.59, 1059.48, 1083.59, 1114.19,
        1126.54, 1121.83, 1105.36, 1165.36, 1180.07, 1193.6, 1150.07, 1145.36,
        1141.83, 1147.71, 1139.48, 1114.19, 1121.24, 1167.13, 1193.01, 1260.07,
        1434.21, 1373.03, 1385.96, 1364.79, 1338.9, 1383.02, 1370.67, 1367.14,
        1299.48, 1303.02, 1291.84, 1255.37, 1281.84, 1295.96, 1286.55, 1420.08,
        1410.67, 1390.08, 1459.49, 1607.74, 1748.93, 1721.28, 1906.59, 1783.64,
        1892.47, 1933.05, 1857.75, 1860.7, 1938.94, 2056.0, 2231.89, 2488.97,
        2354.84, 2337.79, 2494.85, 2407.79, 2428.96, 2533.09, 2544.27, 2670.74,
        3330.79, 3423.73, 3357.85, 3073.12, 3363.14, 3830.23, 3416.68, 3454.33,
        3431.97, 3571.98, 3320.19, 3048.41, 3411.97, 3343.72, 3345.49, 3450.21,
        3401.96, 3220.2, 3401.96, 3480.2, 3486.09, 3536.1, 3697.86, 3749.64,
        3584.33, 3555.51, 3690.22, 3994.94, 4325.55, 4346.73, 4363.79, 4102.01,
        3984.94, 4194.36, 4454.38, 4393.79, 4352.02, 4247.31, 4384.97, 4284.37,
        4802.05, 5563.27, 5709.75, 6400.97, 6586.28, 7043.95, 6568.63, 6306.85,
        6103.3, 6175.66, 5989.18, 5913.88, 6358.61, 6352.14, 5812.7, 6163.89,
        5836.23, 5494.45, 5252.66, 5765.05, 5855.06, 6075.06, 5900.35, 5310.31,
        5657.4, 5686.81, 5542.09, 5773.29, 5953.3, 5568.57, 5496.8, 5477.98,
        5382.08, 4939.71, 4863.82, 5147.36, 5133.83, 5429.73, 5511.5, 5579.16,
        5936.23, 6086.24, 5747.4, 5713.28, 5902.11, 5762.1, 5633.87, 5573.86,
        5733.28, 5939.17, 6037.41, 6346.26, 6435.08, 6326.26, 6563.92, 6590.97,
        6889.82, 6589.81, 6453.91, 6425.68, 6635.69, 6592.15, 6403.32, 6154.48,
        6057.41, 5872.7, 6125.07, 6089.19, 6451.55, 6380.38, 6813.93, 7273.96,
        7686.34, 7547.5, 7703.39, 7693.99, 7171.01, 7687.51, 8514.62, 8476.97,
        8526.39, 8343.44, 8698.16, 8727.58, 9615.87, 10172.37, 10832.4,
        10648.28, 11833.05, 11656.57, 9956.48, 11172.42, 10489.45, 10018.82,
        10280.02, 10881.23, 11234.19, 11309.49, 10998.89, 11173.01, 11308.9,
        11540.69, 11801.88, 11344.21, 10830.06, 10950.07, 10723.58, 10259.43,
        10506.51, 10504.74, 10655.92, 10235.32, 10233.55, 10162.36, 8912.88,
        8295.19, 8859.94, 8232.26, 8094.01, 7916.94, 8405.79, 8451.09, 8775.22,
        8762.29, 9296.43, 9134.66, 8642.87, 8546.39, 8189.31, 8275.78, 8181.07,
        8096.95, 7493.39, 7846.35, 8161.66, 8434.02, 8588.75, 8539.33, 9094.07,
        8825.23, 8325.79, 8997.59, 8578.15, 8576.98, 8425.79, 8642.87, 8415.79,
        9169.95, 9345.25, 9921.17, 10119.42, 10084.13, 10918.29, 10500.62,
        10441.79, 10367.09, 9838.82, 9878.83, 9633.51, 10068.24, 9943.53,
        9979.41, 9835.88, 9774.11, 9807.05, 9605.86, 9732.93, 10048.24,
        10187.66, 10032.95, 9594.1, 9324.09, 9525.27, 9375.26, 8793.46, 9153.48,
        8716.4, 8431.09, 8557.56, 8847.59, 8460.5, 8096.95, 7924.59, 7878.12,
        7931.65, 7898.7, 7601.62, 7659.87, 8053.42, 8082.83, 8836.4, 8237.54,
        8239.31, 8649.93, 8635.21, 9101.72, 8315.78, 7792.23, 7896.94, 8463.44,
        7826.93, 8253.43, 8314.61, 8328.14, 8045.77, 8164.01, 8365.2, 8658.16,
        9340.56, 9407.61, 9177.01, 9410.56, 9929.41, 10199.42, 10821.23,
        10501.8, 10171.78, 10434.15, 10135.31, 10779.46, 10923.59, 10790.64,
        10783.58, 10549.45
      ],
      USDT: [
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000
      ],
      HODLIE: [
        10000, 10001.08, 9988.64, 9877.33, 9960.83, 9595.98, 9600.69, 9597.95,
        9596.73, 9597.36, 9489.1, 9505.54, 9679.71, 9515.53, 9524.06, 9558.26,
        9540.23, 9317.98, 9338.71, 9338.34, 9336.55, 9329.72, 9326.85, 9328.83,
        9328.51, 9343.1, 9309.88, 9191.26, 9316.91, 9418.8, 9373.77, 9559.99,
        9828.92, 9563.83, 9546.3, 9886.73, 9993.12, 9944.2, 10244.74, 10240.47,
        10298.47, 10074.87, 10001.34, 10002.23, 10001.88, 10002.24, 10242.82,
        10261.19, 9876.28, 9859.63, 9859.65, 9858.09, 9855.6, 9856.4, 9706.73,
        9600.66, 9792.07, 9764.31, 9481.57, 9763.91, 9916.61, 9907.23, 9732.05,
        9717.42, 9718.61, 9718.0, 9718.04, 9717.51, 9686.72, 9395.3, 9322.86,
        9333.58, 9332.08, 9333.01, 9208.66, 9364.62, 9463.44, 9649.03, 9655.91,
        9560.61, 9612.32, 9527.86, 9561.29, 9699.75, 9566.58, 9672.91, 9859.16,
        9955.75, 9988.45, 9994.11, 10096.63, 10410.38, 10350.63, 10438.41,
        10436.24, 10315.88, 10250.39, 10251.94, 10250.19, 10250.44, 10190.9,
        10154.4, 10156.25, 10157.72, 10064.32, 10080.69, 10059.79, 10052.44,
        10052.86, 10130.59, 10078.61, 9937.35, 9894.16, 9883.95, 9883.51,
        9883.98, 9614.76, 9598.97, 9597.73, 9598.01, 9580.91, 9576.44, 9576.38,
        9454.7, 9688.81, 9380.96, 9395.91, 9393.79, 9393.16, 9386.82, 9387.48,
        9381.01, 9380.12, 9385.18, 9385.13, 9689.63, 9510.23, 9634.99, 9315.07,
        9261.97, 9098.03, 9106.56, 9231.65, 9061.22, 9047.24, 9012.63, 8996.82,
        8945.72, 8946.23, 8993.6, 9298.64, 9202.06, 8870.91, 8930.42, 8929.04,
        8929.56, 8901.59, 8805.15, 8604.18, 8478.66, 8474.01, 8358.61, 8318.09,
        8315.78, 8309.75, 8309.64, 8312.76, 7953.72, 8015.18, 8074.76, 8290.57,
        8309.41, 8450.51, 8273.47, 8494.74, 8813.03, 8796.27, 8711.45, 8670.83,
        8369.53, 8351.06, 8349.8, 8350.55, 8350.91, 8404.59, 8660.85, 8696.61,
        8841.63, 8984.32, 8990.99, 8959.46, 8804.07, 8804.04, 8803.36, 8804.01,
        8805.7, 9002.27, 9398.21, 9404.91, 9926.14, 10088.28, 9973.6, 9940.66,
        9881.43, 9846.25, 10019.81, 9806.76, 9806.14, 9810.05, 10130.05,
        10123.13, 9998.41, 9890.58, 9814.92, 9754.02, 9756.38, 9768.62, 9777.48,
        9773.89, 9756.49, 9963.51, 9816.66, 10204.68, 10267.39, 10424.51,
        10549.43, 10428.72, 10309.59, 10340.56, 10338.77, 10338.38, 10334.43,
        10334.71, 10337.96, 10334.9, 10346.13, 10339.08, 10443.11, 9996.93,
        9867.02, 9867.34, 9868.28, 9846.28, 9920.91, 9976.61, 9957.86, 9856.01,
        9870.9, 9805.32, 9689.59, 9492.96, 9496.37, 9752.07, 9898.79, 9866.92,
        10018.98, 9686.53, 9651.5, 9649.62, 9649.84, 9650.8, 9648.39, 9649.25,
        9601.22, 9600.18, 9640.56, 9638.55, 9618.01, 9509.08, 9621.44, 9569.3,
        9535.87, 9531.95, 9524.08, 9446.9, 9442.1, 9440.19, 9596.55, 9571.05,
        9553.41, 9492.86, 9480.28, 9480.38, 9479.66, 9479.44, 9479.51, 9480.1,
        9418.92, 9360.19, 9442.79, 9471.66, 9402.55, 9309.84, 9301.63, 9301.68,
        9331.69, 9507.63, 9441.64, 9826.32, 10187.82, 10023.76, 10160.17,
        10327.93, 10254.88, 10193.65, 10210.63, 10188.06, 10196.74, 10346.87,
        10490.68, 10141.55, 9914.96, 9939.28, 9931.97, 9938.06, 9935.24,
        9931.11, 9804.88, 9774.78, 9716.37, 9719.05, 9693.7, 9703.14, 9702.96,
        9611.17, 9609.47, 9611.56, 9800.01, 9916.84, 9807.53, 9793.77, 9856.74,
        9756.54, 9757.21, 10074.53, 9991.61, 10041.49, 9944.78, 9936.56,
        9964.74, 10018.68, 9915.04, 9899.16, 9834.15, 9845.89, 9819.78, 9817.21,
        9979.42, 9947.55, 9883.61, 9520.98, 9466.56, 9468.55, 9466.24, 9641.51,
        9617.82, 9641.75, 9651.65, 9651.84, 9646.22, 9647.53, 9606.69, 9603.88,
        9604.27, 9601.41, 9594.08, 9586.97, 9976.83, 10552.17, 10593.08,
        10562.61, 10544.51, 10547.55, 10580.34, 11208.76, 11191.9, 11165.27,
        11216.65, 11476.48, 11933.89, 11859.03, 11925.2, 11843.38, 11658.14,
        11581.33, 11583.4, 11476.11, 11382.16, 11399.94, 11342.37, 11310.77,
        11295.4, 11296.87, 11226.57, 11355.14, 11023.31, 11052.0, 11028.64,
        10973.25, 11011.57, 11052.81, 10991.1, 10989.79, 10990.92, 10921.16,
        10551.78, 10571.83, 10572.7, 10573.5, 10393.07, 10538.67, 10861.57,
        10802.17, 10889.01, 10883.36, 10852.2, 11001.55, 10806.54, 10707.48,
        10711.58, 10710.24, 10710.22, 10711.32, 10710.67, 10639.23, 10781.35,
        10756.49, 10481.77, 10481.46, 10481.66, 10489.4, 10414.34, 10431.25,
        10416.82, 10417.66, 10417.01, 10419.66, 10572.9, 10671.58, 10599.79,
        10734.61, 11263.83, 11142.48, 11385.76, 11252.44, 11247.76, 11083.36,
        11088.51, 10871.78, 10886.71, 10952.63, 10921.75, 10996.18, 11057.83,
        10976.55, 11114.89, 11070.18, 10976.1, 10893.68, 10892.35, 11015.73,
        10901.1, 10839.5, 10839.32, 10839.65, 11073.69, 11197.18, 11291.42,
        11392.91, 11459.7, 11435.06, 11520.49, 11381.39, 11287.73, 11060.61,
        11059.25, 11059.53, 11061.3, 11059.77, 11060.58, 11188.62, 11200.47,
        11409.72, 11401.05, 11350.36, 11364.72, 11209.11, 11203.71, 11206.9,
        11104.44, 11393.15, 11129.84, 11128.46, 11126.71, 11126.34, 11126.93,
        11125.29, 11125.53, 11120.24, 11128.93, 11173.6, 11110.49, 11008.79,
        10903.37, 10885.21, 10895.89, 10894.31, 10931.01, 11036.62, 10874.6,
        10871.72, 10871.98, 10876.92, 10966.88, 10866.14, 10988.3, 10897.04,
        10872.97, 10860.65, 10813.54, 10946.62, 10602.57, 10602.95, 10527.52,
        10528.25, 10522.95, 10512.31, 10511.4, 10510.57, 10356.67, 10212.28,
        10225.05, 10222.09, 10262.06, 10229.77, 10312.44, 10541.19, 10886.68,
        10851.0, 10978.48, 10954.08, 10952.23, 10779.7, 10746.94, 10676.63,
        10647.85, 10883.51, 10758.05, 10956.95, 10934.38, 10948.01, 10803.91,
        10876.55, 11093.59, 11167.66, 11070.39, 11048.48, 11045.11, 11004.78,
        11621.59, 11718.94, 11885.6, 11919.33, 11852.71, 11587.15, 11587.13,
        11503.83, 11555.11, 11520.98, 11519.74, 11358.26, 11358.16, 11358.36,
        11344.12, 11311.19, 11360.08, 11278.34, 11274.31, 11277.23, 11176.78,
        11172.59, 11147.95, 11154.82, 11159.45, 11123.44, 11280.05, 11314.7,
        11350.24, 11337.82, 11333.83, 11241.59, 11312.18, 11132.61, 11137.71,
        11134.97, 11133.72, 11133.72, 11133.57, 11036.86, 10962.18, 11011.18,
        10867.22, 10856.61, 10837.44, 10829.05, 10828.93, 11054.57, 10944.71,
        10710.5, 10620.13, 10624.37, 10624.16, 10600.68, 10716.29, 10610.85,
        10573.52, 10500.38, 10500.74, 10489.01, 10481.49, 10453.58, 10428.97,
        10479.17, 10458.24, 10436.93, 10408.28, 10470.13, 10518.97, 10557.4,
        10435.8, 10419.45, 10419.22, 10404.38, 10377.62, 10376.33, 10278.84,
        10442.18, 10493.95, 10518.13, 10967.55, 10809.27, 10844.24, 10794.65,
        10779.32, 10816.08, 10811.73, 10805.42, 10714.77, 10715.35, 10714.83,
        10714.39, 10714.55, 10714.59, 10706.13, 11068.11, 11034.86, 10985.12,
        11162.84, 11528.63, 11857.99, 11801.65, 12216.32, 12071.69, 12148.73,
        12083.87, 11931.09, 11945.1, 12025.33, 12011.6, 12341.1, 12790.94,
        12584.76, 12550.46, 12818.78, 12729.28, 12789.52, 12679.8, 12673.65,
        12892.47, 13287.15, 13409.3, 13327.88, 12964.06, 12913.36, 13516.19,
        13034.73, 13081.77, 13043.39, 13049.33, 12973.26, 12709.15, 12825.9,
        12828.0, 12852.45, 12899.08, 12811.54, 12571.86, 12572.7, 12511.3,
        12515.32, 12570.97, 12719.03, 12854.59, 12997.15, 13351.18, 13273.19,
        13180.14, 13562.95, 13587.2, 13610.79, 13350.59, 13366.22, 13363.33,
        13486.85, 13430.96, 13392.86, 13334.96, 13334.86, 13248.58, 13574.73,
        14260.22, 14379.35, 14950.39, 15086.09, 15427.98, 15094.87, 14956.44,
        14892.63, 14643.11, 14660.74, 14667.44, 14783.16, 14909.28, 14545.83,
        14806.63, 14712.16, 14697.39, 14673.68, 15054.99, 14936.21, 15253.69,
        15456.26, 15117.81, 15351.49, 15229.04, 15235.8, 15418.62, 15542.99,
        15212.04, 15216.16, 15218.93, 15223.13, 15081.28, 15079.2, 15078.01,
        14976.1, 15263.28, 15337.33, 15239.24, 15556.34, 15690.37, 15421.86,
        15414.81, 15396.61, 15361.69, 15282.87, 15158.97, 15350.78, 15490.25,
        15488.99, 15800.27, 15890.73, 15940.51, 16248.15, 16152.82, 16561.12,
        16685.7, 16617.15, 16598.13, 16841.43, 17068.28, 17163.13, 17000.14,
        17153.19, 16976.68, 17047.1, 17364.99, 17596.47, 17833.37, 18501.46,
        18496.02, 18374.68, 18265.86, 18388.9, 18760.82, 18030.01, 18807.1,
        18921.91, 18935.44, 18975.35, 18807.36, 19317.45, 19152.64, 19401.33,
        19757.99, 20189.89, 20087.65, 20799.37, 20703.16, 19997.21, 20006.06,
        20005.24, 19685.51, 19941.65, 19811.57, 19843.79, 19818.36, 19705.93,
        19902.34, 19805.55, 19905.73, 20054.38, 19795.21, 19352.86, 19353.65,
        19351.63, 19489.52, 19685.9, 19704.7, 20459.97, 19908.77, 19967.38,
        19917.47, 19433.42, 19395.94, 19396.18, 19394.51, 19391.59, 19151.01,
        19074.57, 19139.67, 19383.64, 19379.25, 19762.8, 19650.71, 19202.24,
        19224.72, 19213.38, 19212.73, 19307.92, 19093.22, 18480.24, 18477.48,
        18471.28, 18677.4, 18788.12, 18778.93, 18726.86, 18562.22, 18372.27,
        18375.96, 18126.38, 18134.71, 18038.83, 18042.5, 17859.52, 18482.97,
        18346.58, 18713.18, 18835.99, 18816.17, 19318.46, 19744.66, 19570.81,
        19743.16, 19487.68, 19476.06, 19662.4, 19809.87, 19747.76, 19661.35,
        19524.88, 19524.75, 19525.61, 19434.71, 19491.95, 19726.94, 19808.27,
        19734.83, 19536.53, 19511.46, 19510.17, 19502.04, 19122.64, 19125.25,
        19122.26, 18982.38, 19061.37, 19213.3, 18941.26, 18642.65, 18643.96,
        18435.17, 18445.09, 18361.12, 18359.55, 18356.95, 18316.68, 18340.36,
        18896.41, 18471.16, 18471.27, 18771.7, 18775.08, 18951.12, 18407.06,
        18376.12, 18371.43, 18377.28, 17943.21, 18269.56, 18261.76, 18383.36,
        18390.04, 18297.97, 18457.11, 18657.46, 19181.88, 19221.74, 19171.05,
        19137.49, 19500.25, 19675.33, 20068.78, 19873.57, 19670.68, 19831.66,
        19852.06, 20009.01, 20097.78, 19947.42, 19948.44, 19803.95
      ]
    },
    2022: {
      TIMESTAMP: [
        '2022-01-01',
        '2022-01-02',
        '2022-01-03',
        '2022-01-04',
        '2022-01-05',
        '2022-01-06',
        '2022-01-07',
        '2022-01-08',
        '2022-01-09',
        '2022-01-10',
        '2022-01-11',
        '2022-01-12',
        '2022-01-13',
        '2022-01-14',
        '2022-01-15',
        '2022-01-16',
        '2022-01-17',
        '2022-01-18',
        '2022-01-19',
        '2022-01-20',
        '2022-01-21',
        '2022-01-22',
        '2022-01-23',
        '2022-01-24',
        '2022-01-25',
        '2022-01-26',
        '2022-01-27',
        '2022-01-28',
        '2022-01-29',
        '2022-01-30',
        '2022-01-31',
        '2022-02-01',
        '2022-02-02',
        '2022-02-03',
        '2022-02-04',
        '2022-02-05',
        '2022-02-06',
        '2022-02-07',
        '2022-02-08',
        '2022-02-09',
        '2022-02-10',
        '2022-02-11',
        '2022-02-12',
        '2022-02-13',
        '2022-02-14',
        '2022-02-15',
        '2022-02-16',
        '2022-02-17',
        '2022-02-18',
        '2022-02-19',
        '2022-02-20',
        '2022-02-21',
        '2022-02-22',
        '2022-02-23',
        '2022-02-24',
        '2022-02-25',
        '2022-02-26',
        '2022-02-27',
        '2022-02-28',
        '2022-03-01',
        '2022-03-02',
        '2022-03-03',
        '2022-03-04',
        '2022-03-05',
        '2022-03-06',
        '2022-03-07',
        '2022-03-08',
        '2022-03-09',
        '2022-03-10',
        '2022-03-11',
        '2022-03-12',
        '2022-03-13',
        '2022-03-14',
        '2022-03-15',
        '2022-03-16',
        '2022-03-17',
        '2022-03-18',
        '2022-03-19',
        '2022-03-20',
        '2022-03-21',
        '2022-03-22',
        '2022-03-23',
        '2022-03-24',
        '2022-03-25',
        '2022-03-26',
        '2022-03-27',
        '2022-03-28',
        '2022-03-29',
        '2022-03-30',
        '2022-03-31',
        '2022-04-01',
        '2022-04-02',
        '2022-04-03',
        '2022-04-04',
        '2022-04-05',
        '2022-04-06',
        '2022-04-07',
        '2022-04-08',
        '2022-04-09',
        '2022-04-10',
        '2022-04-11',
        '2022-04-12',
        '2022-04-13',
        '2022-04-14',
        '2022-04-15',
        '2022-04-16',
        '2022-04-17',
        '2022-04-18',
        '2022-04-19',
        '2022-04-20',
        '2022-04-21',
        '2022-04-22',
        '2022-04-23',
        '2022-04-24',
        '2022-04-25',
        '2022-04-26',
        '2022-04-27',
        '2022-04-28',
        '2022-04-29',
        '2022-04-30',
        '2022-05-01',
        '2022-05-02',
        '2022-05-03',
        '2022-05-04',
        '2022-05-05',
        '2022-05-06',
        '2022-05-07',
        '2022-05-08',
        '2022-05-09',
        '2022-05-10',
        '2022-05-11',
        '2022-05-12',
        '2022-05-13',
        '2022-05-14',
        '2022-05-15',
        '2022-05-16',
        '2022-05-17',
        '2022-05-18',
        '2022-05-19',
        '2022-05-20',
        '2022-05-21',
        '2022-05-22',
        '2022-05-23',
        '2022-05-24',
        '2022-05-25',
        '2022-05-26',
        '2022-05-27',
        '2022-05-28',
        '2022-05-29',
        '2022-05-30',
        '2022-05-31',
        '2022-06-01',
        '2022-06-02',
        '2022-06-03',
        '2022-06-04',
        '2022-06-05',
        '2022-06-06',
        '2022-06-07',
        '2022-06-08',
        '2022-06-09',
        '2022-06-10',
        '2022-06-11',
        '2022-06-12',
        '2022-06-13',
        '2022-06-14',
        '2022-06-15',
        '2022-06-16',
        '2022-06-17',
        '2022-06-18',
        '2022-06-19',
        '2022-06-20',
        '2022-06-21',
        '2022-06-22',
        '2022-06-23',
        '2022-06-24',
        '2022-06-25',
        '2022-06-26',
        '2022-06-27',
        '2022-06-28',
        '2022-06-29',
        '2022-06-30',
        '2022-07-01',
        '2022-07-02',
        '2022-07-03',
        '2022-07-04',
        '2022-07-05',
        '2022-07-06',
        '2022-07-07',
        '2022-07-08',
        '2022-07-09',
        '2022-07-10',
        '2022-07-11',
        '2022-07-12',
        '2022-07-13',
        '2022-07-14',
        '2022-07-15',
        '2022-07-16',
        '2022-07-17',
        '2022-07-18',
        '2022-07-19',
        '2022-07-20',
        '2022-07-21',
        '2022-07-22',
        '2022-07-23',
        '2022-07-24',
        '2022-07-25',
        '2022-07-26',
        '2022-07-27',
        '2022-07-28',
        '2022-07-29',
        '2022-07-30',
        '2022-07-31',
        '2022-08-01',
        '2022-08-02',
        '2022-08-03',
        '2022-08-04',
        '2022-08-05',
        '2022-08-06',
        '2022-08-07',
        '2022-08-08',
        '2022-08-09',
        '2022-08-10',
        '2022-08-11',
        '2022-08-12',
        '2022-08-13',
        '2022-08-14',
        '2022-08-15',
        '2022-08-16',
        '2022-08-17',
        '2022-08-18',
        '2022-08-19',
        '2022-08-20',
        '2022-08-21',
        '2022-08-22',
        '2022-08-23',
        '2022-08-24',
        '2022-08-25',
        '2022-08-26',
        '2022-08-27',
        '2022-08-28',
        '2022-08-29',
        '2022-08-30',
        '2022-08-31',
        '2022-09-01',
        '2022-09-02',
        '2022-09-03',
        '2022-09-04',
        '2022-09-05',
        '2022-09-06',
        '2022-09-07',
        '2022-09-08',
        '2022-09-09',
        '2022-09-10',
        '2022-09-11',
        '2022-09-12',
        '2022-09-13',
        '2022-09-14',
        '2022-09-15',
        '2022-09-16',
        '2022-09-17',
        '2022-09-18',
        '2022-09-19',
        '2022-09-20',
        '2022-09-21',
        '2022-09-22',
        '2022-09-23',
        '2022-09-24',
        '2022-09-25',
        '2022-09-26',
        '2022-09-27',
        '2022-09-28',
        '2022-09-29',
        '2022-09-30',
        '2022-10-01',
        '2022-10-02',
        '2022-10-03',
        '2022-10-04',
        '2022-10-05',
        '2022-10-06',
        '2022-10-07',
        '2022-10-08',
        '2022-10-09',
        '2022-10-10',
        '2022-10-11',
        '2022-10-12',
        '2022-10-13',
        '2022-10-14',
        '2022-10-15',
        '2022-10-16',
        '2022-10-17',
        '2022-10-18',
        '2022-10-19',
        '2022-10-20',
        '2022-10-21',
        '2022-10-22',
        '2022-10-23',
        '2022-10-24',
        '2022-10-25',
        '2022-10-26',
        '2022-10-27',
        '2022-10-28',
        '2022-10-29',
        '2022-10-30',
        '2022-10-31',
        '2022-11-01',
        '2022-11-02',
        '2022-11-03',
        '2022-11-04',
        '2022-11-05',
        '2022-11-06',
        '2022-11-07',
        '2022-11-08',
        '2022-11-09',
        '2022-11-10',
        '2022-11-11',
        '2022-11-12',
        '2022-11-13',
        '2022-11-14',
        '2022-11-15',
        '2022-11-16',
        '2022-11-17',
        '2022-11-18',
        '2022-11-19',
        '2022-11-20',
        '2022-11-21',
        '2022-11-22',
        '2022-11-23',
        '2022-11-24',
        '2022-11-25',
        '2022-11-26',
        '2022-11-27',
        '2022-11-28',
        '2022-11-29',
        '2022-11-30',
        '2022-12-01',
        '2022-12-02',
        '2022-12-03',
        '2022-12-04',
        '2022-12-05',
        '2022-12-06',
        '2022-12-07',
        '2022-12-08',
        '2022-12-09',
        '2022-12-10',
        '2022-12-11',
        '2022-12-12',
        '2022-12-13',
        '2022-12-14',
        '2022-12-15',
        '2022-12-16',
        '2022-12-17',
        '2022-12-18',
        '2022-12-19',
        '2022-12-20',
        '2022-12-21',
        '2022-12-22',
        '2022-12-23',
        '2022-12-24',
        '2022-12-25',
        '2022-12-26',
        '2022-12-27',
        '2022-12-28',
        '2022-12-29',
        '2022-12-30',
        '2022-12-31'
      ],
      BTC: [
        10000.0, 10264.8, 10224.69, 10000.21, 9987.66, 9398.52, 9343.11,
        8988.44, 9068.35, 9132.01, 9002.11, 9241.87, 9486.34, 9216.19, 9358.16,
        9355.32, 9345.06, 9104.28, 9191.64, 9082.58, 8907.4, 7814.79, 7545.94,
        7683.0, 7869.29, 7960.54, 7899.58, 7941.96, 8147.44, 8252.39, 8175.75,
        8311.34, 8373.02, 8007.23, 7991.26, 8793.69, 8996.67, 8999.03, 9535.25,
        9533.93, 9641.0, 9511.14, 9121.12, 9125.56, 9167.97, 9234.29, 9556.92,
        9522.59, 8808.83, 8641.86, 8658.17, 8398.69, 8123.27, 8204.21, 8145.98,
        8193.98, 8530.24, 8469.88, 8182.82, 9309.67, 9554.24, 9534.9, 9194.05,
        8439.49, 8539.06, 8432.87, 8267.85, 8341.59, 9064.22, 8534.11, 8420.44,
        8460.53, 8161.71, 8509.02, 8539.52, 8845.89, 8868.57, 9019.89, 9077.73,
        8965.54, 8897.17, 9202.07, 9193.31, 9509.28, 9585.12, 9599.11, 10085.7,
        10286.41, 10241.08, 10192.05, 9907.16, 10032.77, 9977.03, 10103.47,
        10097.31, 9921.58, 9414.59, 9440.2, 9152.7, 9205.15, 9111.8, 8604.29,
        8617.61, 8931.59, 8621.02, 8755.98, 8742.65, 8654.84, 8854.71, 8956.76,
        8972.4, 8743.98, 8592.35, 8615.1, 8557.11, 8729.91, 8269.92, 8454.55,
        8610.46, 8353.79, 8276.28, 8288.44, 8349.98, 8165.77, 8585.99, 7840.99,
        7789.19, 7582.95, 7416.17, 6726.51, 6661.01, 6245.13, 6236.14, 6449.91,
        6542.91, 6727.12, 6495.12, 6567.17, 6269.59, 6531.2, 6307.85, 6364.39,
        6550.76, 6320.46, 6405.79, 6444.72, 6384.86, 6236.45, 6273.17, 6367.11,
        6873.77, 6895.59, 6436.42, 6595.62, 6432.79, 6449.59, 6496.04, 6793.08,
        6763.75, 6558.32, 6505.76, 6313.3, 6195.95, 5841.03, 4887.36, 4673.38,
        4862.81, 4406.4, 4425.04, 4151.49, 4477.84, 4433.36, 4521.5, 4349.77,
        4542.01, 4611.08, 4637.12, 4574.9, 4503.63, 4394.06, 4373.64, 4088.23,
        4176.09, 4182.74, 4175.95, 4362.83, 4421.59, 4438.58, 4679.7, 4711.05,
        4679.56, 4509.13, 4328.99, 4192.26, 4294.31, 4435.94, 4520.16, 4607.98,
        4544.26, 4715.27, 5085.1, 5043.4, 4980.23, 4917.31, 4861.65, 4902.36,
        4686.24, 4567.66, 4949.14, 5184.66, 5180.54, 5128.2, 5058.2, 5012.19,
        4998.37, 4961.86, 4894.86, 5029.2, 5014.1, 5029.46, 5152.53, 5013.39,
        5158.88, 5169.81, 5244.66, 5276.65, 5248.87, 5195.87, 5160.0, 5051.58,
        5051.05, 4538.41, 4573.29, 4666.25, 4581.86, 4646.67, 4650.62, 4668.48,
        4459.95, 4331.76, 4306.62, 4371.53, 4307.31, 4360.96, 4344.37, 4325.15,
        4282.78, 4291.44, 4285.13, 4091.39, 4184.01, 4177.29, 4618.89, 4707.8,
        4681.38, 4830.16, 4375.92, 4391.49, 4268.8, 4268.75, 4354.73, 4207.74,
        4241.55, 4102.52, 4000.46, 4197.07, 4183.13, 4086.45, 4065.26, 4140.99,
        4128.4, 4216.14, 4209.73, 4194.32, 4181.21, 4147.97, 4223.34, 4392.25,
        4368.62, 4306.07, 4229.98, 4189.1, 4197.13, 4139.24, 4119.73, 4144.67,
        4204.31, 4145.63, 4124.27, 4176.41, 4222.31, 4180.4, 4141.42, 4118.41,
        4152.02, 4152.66, 4239.34, 4189.19, 4341.12, 4504.21, 4385.75, 4463.73,
        4491.38, 4454.35, 4431.17, 4430.95, 4351.18, 4369.7, 4582.31, 4608.04,
        4573.91, 4427.26, 3994.14, 3434.81, 3856.43, 3661.2, 3642.75, 3556.78,
        3550.76, 3652.44, 3606.82, 3615.76, 3605.98, 3621.66, 3526.18, 3405.73,
        3501.94, 3572.83, 3594.46, 3576.43, 3553.96, 3585.92, 3509.7, 3569.17,
        3710.39, 3670.79, 3687.38, 3656.95, 3700.7, 3666.19, 3672.65, 3644.09,
        3721.98, 3707.07, 3708.22, 3700.25, 3712.49, 3846.4, 3859.41, 3751.85,
        3618.48, 3621.91, 3626.74, 3552.12, 3650.02, 3627.44, 3634.69, 3633.5,
        3640.63, 3639.75, 3643.41, 3611.44, 3574.03, 3595.99, 3588.14
      ],
      ETH: [
        10000.0, 10222.13, 10412.02, 10206.08, 10381.89, 9613.79, 9328.99,
        8654.98, 8482.74, 8648.54, 8331.17, 8792.1, 9147.69, 8832.72, 9013.28,
        9078.87, 9108.21, 8684.36, 8648.02, 8506.16, 8326.52, 6924.57, 6472.31,
        6652.19, 6562.41, 6630.65, 6632.29, 6480.53, 6842.15, 7072.71, 7006.31,
        7290.08, 7584.0, 7287.52, 7236.27, 8040.33, 8213.04, 8131.16, 8573.16,
        8470.42, 8843.35, 8475.59, 7872.17, 7930.95, 7894.34, 8000.62, 8502.46,
        8531.21, 7886.81, 7547.27, 7461.67, 7214.46, 7122.62, 7084.44, 7172.89,
        6980.58, 7593.6, 7603.91, 7154.31, 7881.01, 8090.9, 8053.9, 7708.99,
        7104.32, 7263.04, 7151.6, 6811.92, 6986.48, 7389.86, 7106.23, 6992.0,
        7037.89, 6813.58, 6992.95, 7131.6, 7472.14, 7669.03, 7974.72, 7985.36,
        7825.41, 7910.53, 8159.99, 8172.94, 8460.84, 8429.62, 8525.8, 8894.5,
        9169.89, 9211.28, 9220.38, 8948.66, 9388.18, 9430.2, 9641.62, 9594.54,
        9368.27, 8673.78, 8802.61, 8679.33, 8833.67, 8765.91, 8161.05, 8198.1,
        8494.87, 8200.45, 8250.27, 8326.44, 8284.6, 8338.06, 8427.84, 8393.2,
        8115.13, 8047.73, 8039.4, 7967.38, 8185.65, 7675.36, 7820.73, 7966.48,
        7668.89, 7565.39, 7635.49, 7784.91, 7575.43, 7957.34, 7444.66, 7331.75,
        7080.49, 6907.78, 6238.37, 6311.25, 5668.11, 5234.12, 5600.33, 5592.25,
        5794.98, 5531.0, 5662.25, 5299.21, 5458.12, 5313.52, 5371.78, 5536.71,
        5380.78, 5393.79, 5345.59, 4952.42, 4738.93, 4853.72, 4925.32, 5438.78,
        5323.63, 4946.4, 4989.22, 4849.95, 4893.23, 4938.7, 5046.75, 4920.18,
        4896.57, 4865.94, 4557.2, 4211.19, 3961.7, 3246.78, 3201.13, 3312.17,
        2902.21, 2949.36, 2695.75, 3105.25, 3012.57, 3094.94, 2888.61, 3105.27,
        3346.63, 3379.55, 3302.92, 3270.45, 3123.44, 3016.87, 2784.76, 2896.12,
        2912.04, 2926.64, 3109.0, 3132.72, 3238.65, 3345.35, 3349.68, 3308.53,
        3178.91, 2988.72, 2837.07, 2955.09, 3229.73, 3397.64, 3694.22, 3700.26,
        4119.47, 4252.37, 4152.0, 4286.95, 4188.15, 4194.11, 4366.86, 4031.39,
        3838.12, 4422.74, 4748.72, 4738.14, 4621.39, 4582.66, 4430.35, 4481.11,
        4412.35, 4343.74, 4666.28, 4660.97, 4637.12, 4837.29, 4634.64, 5032.3,
        5095.14, 5254.43, 5382.34, 5257.42, 5155.42, 5091.82, 4975.53, 5078.3,
        4406.82, 4280.23, 4402.88, 4318.93, 4499.42, 4517.4, 4608.94, 4213.5,
        4037.37, 3983.59, 4191.14, 4204.34, 4266.79, 4315.64, 4299.3, 4230.34,
        4261.6, 4369.48, 4295.63, 4448.52, 4439.66, 4677.29, 4851.16, 4749.07,
        4640.82, 4317.77, 4458.1, 4012.37, 3896.03, 3985.98, 3647.18, 3769.54,
        3620.89, 3383.6, 3600.26, 3617.24, 3575.65, 3511.59, 3610.88, 3617.87,
        3639.71, 3615.88, 3597.41, 3571.02, 3509.03, 3578.51, 3696.92, 3687.09,
        3674.53, 3627.33, 3568.41, 3581.53, 3515.07, 3484.28, 3521.3, 3515.64,
        3524.75, 3457.35, 3558.86, 3620.86, 3570.5, 3499.16, 3489.89, 3540.04,
        3577.71, 3714.43, 3662.28, 3966.21, 4266.38, 4127.6, 4239.73, 4382.21,
        4317.44, 4259.34, 4293.83, 4111.55, 4165.16, 4487.37, 4440.2, 4357.23,
        4241.36, 3586.78, 3040.2, 3601.89, 3482.89, 3429.27, 3341.17, 3318.65,
        3388.99, 3307.65, 3282.41, 3291.49, 3330.02, 3108.81, 3013.88, 3094.59,
        3194.52, 3273.65, 3262.2, 3267.81, 3308.42, 3184.82, 3327.25, 3533.27,
        3475.03, 3510.61, 3378.49, 3469.83, 3424.97, 3408.06, 3346.88, 3493.53,
        3438.66, 3452.24, 3433.71, 3461.29, 3590.17, 3568.31, 3436.65, 3206.33,
        3225.69, 3230.41, 3162.27, 3306.35, 3290.51, 3307.55, 3314.35, 3314.73,
        3311.3, 3314.73, 3294.68, 3225.34, 3258.26, 3259.32
      ],
      SOL: [
        10000.0, 10427.68, 10402.38, 10005.89, 9937.06, 8917.0, 8940.53,
        8045.77, 8486.39, 8319.9, 7936.95, 8250.49, 8948.76, 8628.16, 8644.04,
        8735.22, 8695.81, 8183.43, 8286.96, 8061.06, 7768.11, 6486.27, 5483.26,
        5653.87, 5419.74, 5500.91, 5393.85, 5196.78, 5315.61, 5611.51, 5466.79,
        5875.65, 6465.09, 5952.71, 5917.99, 6470.39, 6749.81, 6635.1, 6946.88,
        6652.74, 6763.34, 6359.79, 5622.68, 5655.63, 5514.45, 5696.81, 6038.59,
        6033.89, 5533.86, 5285.6, 5333.26, 5470.91, 5015.0, 5005.59, 5093.24,
        5129.13, 5573.27, 5336.79, 5029.12, 5787.4, 5759.16, 5945.65, 5631.51,
        5203.24, 5297.96, 5043.24, 4838.52, 4809.1, 5169.13, 4906.76, 4775.58,
        4815.58, 4605.57, 4752.63, 4900.88, 5055.59, 5205.01, 5229.13, 5438.56,
        5260.9, 5219.14, 5382.67, 5549.74, 6022.71, 5766.22, 5962.7, 6203.31,
        6442.74, 6517.44, 7066.3, 7348.08, 8034.01, 7896.35, 8092.25, 7771.64,
        7567.51, 6775.69, 6973.35, 6502.74, 6525.1, 6556.27, 5879.17, 6001.53,
        6194.48, 5914.47, 5943.88, 6005.06, 5999.18, 6026.24, 6345.08, 6263.31,
        5971.53, 5927.41, 5965.65, 5854.47, 5923.88, 5668.57, 5762.1, 5760.34,
        5533.86, 5021.48, 5241.49, 5151.48, 5049.12, 5417.97, 4934.41, 4829.69,
        4579.68, 4461.44, 3858.46, 3832.0, 2810.75, 2543.1, 2949.0, 3073.12,
        3396.09, 3197.25, 3326.67, 2993.71, 3021.95, 2925.46, 2957.82, 3089.01,
        2899.0, 2919.0, 2864.28, 2588.97, 2431.91, 2588.97, 2638.39, 2793.69,
        2710.16, 2373.08, 2410.73, 2253.66, 2254.25, 2303.67, 2501.33, 2323.67,
        2294.84, 2350.13, 2217.19, 2036.0, 1841.87, 1598.91, 1678.33, 2007.77,
        1780.69, 1819.52, 1863.05, 2040.71, 2050.71, 2156.01, 2051.88, 2220.13,
        2484.27, 2471.91, 2338.96, 2276.6, 2076.0, 2008.36, 1911.29, 1937.17,
        1970.12, 1958.94, 2146.01, 2117.77, 2165.43, 2253.07, 2266.02, 2246.61,
        2163.66, 1972.46, 1942.47, 2008.36, 2161.89, 2224.25, 2341.91, 2304.85,
        2412.49, 2663.1, 2496.03, 2516.62, 2414.85, 2350.72, 2416.61, 2223.66,
        2113.65, 2344.85, 2547.2, 2514.85, 2597.8, 2501.33, 2433.67, 2422.5,
        2281.9, 2268.96, 2377.2, 2379.55, 2397.79, 2483.68, 2374.84, 2468.38,
        2508.38, 2633.68, 2736.05, 2654.27, 2546.04, 2533.09, 2374.26, 2394.85,
        2129.54, 2064.24, 2151.89, 2050.13, 2086.6, 2083.65, 2075.41, 1900.11,
        1847.17, 1841.28, 1886.58, 1869.53, 1878.93, 1850.11, 1833.63, 1829.52,
        1875.99, 1894.23, 1832.47, 1924.82, 1972.46, 2041.3, 2076.59, 2048.95,
        2193.66, 1964.24, 2006.0, 1943.65, 1887.76, 1976.58, 1840.11, 1932.46,
        1865.99, 1802.46, 1904.82, 1985.41, 1969.53, 1899.52, 1966.59, 1931.88,
        1956.0, 1988.36, 1930.71, 1915.4, 1895.4, 1933.05, 2000.71, 2003.65,
        1961.88, 1947.18, 1911.88, 1924.82, 1886.58, 1840.7, 1831.29, 1801.87,
        1761.28, 1744.81, 1780.69, 1824.23, 1775.4, 1716.57, 1661.27, 1658.33,
        1647.75, 1717.16, 1673.04, 1823.05, 1844.23, 1804.22, 1891.88, 1926.0,
        1935.41, 1918.35, 1904.82, 1797.75, 1817.75, 1993.05, 2173.65, 1954.82,
        1730.69, 1383.02, 836.52, 1057.71, 940.06, 877.11, 804.75, 807.69,
        835.93, 842.4, 805.93, 774.75, 757.69, 724.74, 694.16, 721.81, 830.05,
        858.29, 831.81, 827.11, 847.11, 785.34, 789.46, 817.11, 794.75, 807.1,
        783.57, 802.98, 815.34, 828.87, 797.11, 804.16, 797.7, 798.87, 792.99,
        782.4, 810.64, 835.93, 823.57, 734.16, 731.22, 731.81, 693.57, 722.4,
        707.69, 693.57, 692.39, 671.8, 668.86, 657.69, 649.45, 571.8, 538.86,
        577.68
      ],
      USDT: [
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0
      ],
      HODLIE: [
        10000.0, 10001.08, 9988.64, 9877.33, 9960.83, 9595.98, 9600.69, 9597.95,
        9596.73, 9597.36, 9489.1, 9505.54, 9679.71, 9515.53, 9524.06, 9558.26,
        9540.23, 9317.98, 9338.71, 9338.34, 9336.55, 9329.72, 9326.85, 9328.83,
        9328.51, 9343.1, 9309.88, 9191.26, 9316.91, 9418.8, 9373.77, 9559.99,
        9828.92, 9563.83, 9546.3, 9886.73, 9993.12, 9944.2, 10244.74, 10240.47,
        10298.47, 10074.87, 10001.34, 10002.23, 10001.88, 10002.24, 10242.82,
        10261.19, 9876.28, 9859.63, 9859.65, 9858.09, 9855.6, 9856.4, 9706.73,
        9600.66, 9792.07, 9764.31, 9481.57, 9763.91, 9916.61, 9907.23, 9732.05,
        9717.42, 9718.61, 9718.0, 9718.04, 9717.51, 9686.72, 9395.3, 9322.86,
        9333.58, 9332.08, 9333.01, 9208.66, 9364.62, 9463.44, 9649.03, 9655.91,
        9560.61, 9612.32, 9527.86, 9561.29, 9699.75, 9566.58, 9672.91, 9859.16,
        9955.75, 9988.45, 9994.11, 10096.63, 10410.38, 10350.63, 10438.41,
        10436.24, 10315.88, 10250.39, 10251.94, 10250.19, 10250.44, 10190.9,
        10154.4, 10156.25, 10157.72, 10064.32, 10080.69, 10059.79, 10052.44,
        10052.86, 10130.59, 10078.61, 9937.35, 9894.16, 9883.95, 9883.51,
        9883.98, 9614.76, 9598.97, 9597.73, 9598.01, 9580.91, 9576.44, 9576.38,
        9454.7, 9688.81, 9380.96, 9395.91, 9393.79, 9393.16, 9386.82, 9387.48,
        9381.01, 9380.12, 9385.18, 9385.13, 9689.63, 9510.23, 9634.99, 9315.07,
        9261.97, 9098.03, 9106.56, 9231.65, 9061.22, 9047.24, 9012.63, 8996.82,
        8945.72, 8946.23, 8993.6, 9298.64, 9202.06, 8870.91, 8930.42, 8929.04,
        8929.56, 8901.59, 8805.15, 8604.18, 8478.66, 8474.01, 8358.61, 8318.09,
        8315.78, 8309.75, 8309.64, 8312.76, 7953.72, 8015.18, 8074.76, 8290.57,
        8309.41, 8450.51, 8273.47, 8494.74, 8813.03, 8796.27, 8711.45, 8670.83,
        8369.53, 8351.06, 8349.8, 8350.55, 8350.91, 8404.59, 8660.85, 8696.61,
        8841.63, 8984.32, 8990.99, 8959.46, 8804.07, 8804.04, 8803.36, 8804.01,
        8805.7, 9002.27, 9398.21, 9404.91, 9926.14, 10088.28, 9973.6, 9940.66,
        9881.43, 9846.25, 10019.81, 9806.76, 9806.14, 9810.05, 10130.05,
        10123.13, 9998.41, 9890.58, 9814.92, 9754.02, 9756.38, 9768.62, 9777.48,
        9773.89, 9756.49, 9963.51, 9816.66, 10204.68, 10267.39, 10424.51,
        10549.43, 10428.72, 10309.59, 10340.56, 10338.77, 10338.38, 10334.43,
        10334.71, 10337.96, 10334.9, 10346.13, 10339.08, 10443.11, 9996.93,
        9867.02, 9867.34, 9868.28, 9846.28, 9920.91, 9976.61, 9957.86, 9856.01,
        9870.9, 9805.32, 9689.59, 9492.96, 9496.37, 9752.07, 9898.79, 9866.92,
        10018.98, 9686.53, 9651.5, 9649.62, 9649.84, 9650.8, 9648.39, 9649.25,
        9601.22, 9600.18, 9640.56, 9638.55, 9618.01, 9509.08, 9621.44, 9569.3,
        9535.87, 9531.95, 9524.08, 9446.9, 9442.1, 9440.19, 9596.55, 9571.05,
        9553.41, 9492.86, 9480.28, 9480.38, 9479.66, 9479.44, 9479.51, 9480.1,
        9418.92, 9360.19, 9442.79, 9471.66, 9402.55, 9309.84, 9301.63, 9301.68,
        9331.69, 9507.63, 9441.64, 9826.32, 10187.82, 10023.76, 10160.17,
        10327.93, 10254.88, 10193.65, 10210.63, 10188.06, 10196.74, 10346.87,
        10490.68, 10141.55, 9914.96, 9939.28, 9931.97, 9938.06, 9935.24,
        9931.11, 9804.88, 9774.78, 9716.37, 9719.05, 9693.7, 9703.14, 9702.96,
        9611.17, 9609.47, 9611.56, 9800.01, 9916.84, 9807.53, 9793.77, 9856.74,
        9756.54, 9757.21, 10074.53, 9991.61, 10041.49, 9944.78, 9936.56,
        9964.74, 10018.68, 9915.04, 9899.16, 9834.15, 9845.89, 9819.78, 9817.21,
        9979.42, 9947.55, 9883.61, 9520.98, 9466.56, 9468.55, 9466.24, 9641.51,
        9617.82, 9641.75, 9651.65, 9651.84, 9646.22, 9647.53, 9606.69, 9603.88,
        9604.27, 9601.41
      ]
    },
    2023: {
      TIMESTAMP: [
        '2023-01-01',
        '2023-01-02',
        '2023-01-03',
        '2023-01-04',
        '2023-01-05',
        '2023-01-06',
        '2023-01-07',
        '2023-01-08',
        '2023-01-09',
        '2023-01-10',
        '2023-01-11',
        '2023-01-12',
        '2023-01-13',
        '2023-01-14',
        '2023-01-15',
        '2023-01-16',
        '2023-01-17',
        '2023-01-18',
        '2023-01-19',
        '2023-01-20',
        '2023-01-21',
        '2023-01-22',
        '2023-01-23',
        '2023-01-24',
        '2023-01-25',
        '2023-01-26',
        '2023-01-27',
        '2023-01-28',
        '2023-01-29',
        '2023-01-30',
        '2023-01-31',
        '2023-02-01',
        '2023-02-02',
        '2023-02-03',
        '2023-02-04',
        '2023-02-05',
        '2023-02-06',
        '2023-02-07',
        '2023-02-08',
        '2023-02-09',
        '2023-02-10',
        '2023-02-11',
        '2023-02-12',
        '2023-02-13',
        '2023-02-14',
        '2023-02-15',
        '2023-02-16',
        '2023-02-17',
        '2023-02-18',
        '2023-02-19',
        '2023-02-20',
        '2023-02-21',
        '2023-02-22',
        '2023-02-23',
        '2023-02-24',
        '2023-02-25',
        '2023-02-26',
        '2023-02-27',
        '2023-02-28',
        '2023-03-01',
        '2023-03-02',
        '2023-03-03',
        '2023-03-04',
        '2023-03-05',
        '2023-03-06',
        '2023-03-07',
        '2023-03-08',
        '2023-03-09',
        '2023-03-10',
        '2023-03-11',
        '2023-03-12',
        '2023-03-13',
        '2023-03-14',
        '2023-03-15',
        '2023-03-16',
        '2023-03-17',
        '2023-03-18',
        '2023-03-19',
        '2023-03-20',
        '2023-03-21',
        '2023-03-22',
        '2023-03-23',
        '2023-03-24',
        '2023-03-25',
        '2023-03-26',
        '2023-03-27',
        '2023-03-28',
        '2023-03-29',
        '2023-03-30',
        '2023-03-31',
        '2023-04-01',
        '2023-04-02',
        '2023-04-03',
        '2023-04-04',
        '2023-04-05',
        '2023-04-06',
        '2023-04-07',
        '2023-04-08',
        '2023-04-09',
        '2023-04-10',
        '2023-04-11',
        '2023-04-12',
        '2023-04-13',
        '2023-04-14',
        '2023-04-15',
        '2023-04-16',
        '2023-04-17',
        '2023-04-18',
        '2023-04-19',
        '2023-04-20',
        '2023-04-21',
        '2023-04-22',
        '2023-04-23',
        '2023-04-24',
        '2023-04-25',
        '2023-04-26',
        '2023-04-27',
        '2023-04-28',
        '2023-04-29',
        '2023-04-30',
        '2023-05-01',
        '2023-05-02',
        '2023-05-03',
        '2023-05-04',
        '2023-05-05',
        '2023-05-06',
        '2023-05-07',
        '2023-05-08',
        '2023-05-09',
        '2023-05-10',
        '2023-05-11',
        '2023-05-12',
        '2023-05-13',
        '2023-05-14',
        '2023-05-15',
        '2023-05-16',
        '2023-05-17',
        '2023-05-18',
        '2023-05-19',
        '2023-05-20',
        '2023-05-21',
        '2023-05-22',
        '2023-05-23',
        '2023-05-24',
        '2023-05-25',
        '2023-05-26',
        '2023-05-27',
        '2023-05-28',
        '2023-05-29',
        '2023-05-30',
        '2023-05-31',
        '2023-06-01',
        '2023-06-02',
        '2023-06-03',
        '2023-06-04',
        '2023-06-05',
        '2023-06-06',
        '2023-06-07',
        '2023-06-08',
        '2023-06-09',
        '2023-06-10',
        '2023-06-11',
        '2023-06-12',
        '2023-06-13',
        '2023-06-14',
        '2023-06-15',
        '2023-06-16',
        '2023-06-17',
        '2023-06-18',
        '2023-06-19',
        '2023-06-20',
        '2023-06-21',
        '2023-06-22',
        '2023-06-23',
        '2023-06-24',
        '2023-06-25',
        '2023-06-26',
        '2023-06-27',
        '2023-06-28',
        '2023-06-29',
        '2023-06-30',
        '2023-07-01',
        '2023-07-02',
        '2023-07-03',
        '2023-07-04',
        '2023-07-05',
        '2023-07-06',
        '2023-07-07',
        '2023-07-08',
        '2023-07-09',
        '2023-07-10',
        '2023-07-11',
        '2023-07-12',
        '2023-07-13',
        '2023-07-14',
        '2023-07-15',
        '2023-07-16',
        '2023-07-17',
        '2023-07-18',
        '2023-07-19',
        '2023-07-20',
        '2023-07-21',
        '2023-07-22',
        '2023-07-23',
        '2023-07-24',
        '2023-07-25',
        '2023-07-26',
        '2023-07-27',
        '2023-07-28',
        '2023-07-29',
        '2023-07-30',
        '2023-07-31',
        '2023-08-01',
        '2023-08-02',
        '2023-08-03',
        '2023-08-04',
        '2023-08-05',
        '2023-08-06',
        '2023-08-07',
        '2023-08-08',
        '2023-08-09',
        '2023-08-10',
        '2023-08-11',
        '2023-08-12',
        '2023-08-13',
        '2023-08-14',
        '2023-08-15',
        '2023-08-16',
        '2023-08-17',
        '2023-08-18',
        '2023-08-19',
        '2023-08-20',
        '2023-08-21',
        '2023-08-22',
        '2023-08-23',
        '2023-08-24',
        '2023-08-25',
        '2023-08-26',
        '2023-08-27',
        '2023-08-28',
        '2023-08-29',
        '2023-08-30',
        '2023-08-31',
        '2023-09-01',
        '2023-09-02',
        '2023-09-03',
        '2023-09-04',
        '2023-09-05',
        '2023-09-06',
        '2023-09-07',
        '2023-09-08',
        '2023-09-09',
        '2023-09-10',
        '2023-09-11',
        '2023-09-12',
        '2023-09-13',
        '2023-09-14',
        '2023-09-15',
        '2023-09-16',
        '2023-09-17',
        '2023-09-18',
        '2023-09-19',
        '2023-09-20',
        '2023-09-21',
        '2023-09-22',
        '2023-09-23',
        '2023-09-24',
        '2023-09-25',
        '2023-09-26',
        '2023-09-27',
        '2023-09-28',
        '2023-09-29',
        '2023-09-30',
        '2023-10-01',
        '2023-10-02',
        '2023-10-03',
        '2023-10-04',
        '2023-10-05',
        '2023-10-06',
        '2023-10-07',
        '2023-10-08',
        '2023-10-09',
        '2023-10-10',
        '2023-10-11',
        '2023-10-12',
        '2023-10-13',
        '2023-10-14',
        '2023-10-15',
        '2023-10-16',
        '2023-10-17',
        '2023-10-18',
        '2023-10-19',
        '2023-10-20',
        '2023-10-21',
        '2023-10-22',
        '2023-10-23',
        '2023-10-24',
        '2023-10-25',
        '2023-10-26',
        '2023-10-27',
        '2023-10-28',
        '2023-10-29',
        '2023-10-30',
        '2023-10-31',
        '2023-11-01',
        '2023-11-02',
        '2023-11-03',
        '2023-11-04',
        '2023-11-05',
        '2023-11-06',
        '2023-11-07',
        '2023-11-08',
        '2023-11-09',
        '2023-11-10',
        '2023-11-11',
        '2023-11-12',
        '2023-11-13',
        '2023-11-14',
        '2023-11-15',
        '2023-11-16',
        '2023-11-17',
        '2023-11-18',
        '2023-11-19',
        '2023-11-20',
        '2023-11-21',
        '2023-11-22',
        '2023-11-23',
        '2023-11-24',
        '2023-11-25',
        '2023-11-26',
        '2023-11-27',
        '2023-11-28',
        '2023-11-29',
        '2023-11-30',
        '2023-12-01',
        '2023-12-02',
        '2023-12-03',
        '2023-12-04',
        '2023-12-05',
        '2023-12-06',
        '2023-12-07',
        '2023-12-08',
        '2023-12-09',
        '2023-12-10',
        '2023-12-11',
        '2023-12-12',
        '2023-12-13',
        '2023-12-14',
        '2023-12-15',
        '2023-12-16',
        '2023-12-17',
        '2023-12-18',
        '2023-12-19',
        '2023-12-20',
        '2023-12-21',
        '2023-12-22',
        '2023-12-23',
        '2023-12-24',
        '2023-12-25',
        '2023-12-26',
        '2023-12-27',
        '2023-12-28',
        '2023-12-29',
        '2023-12-30',
        '2023-12-31'
      ],
      BTC: [
        10000.0, 10049.21, 10107.49, 10094.6, 10185.13, 10191.9, 10264.83,
        10251.51, 10267.75, 10414.02, 10549.16, 10626.83, 11448.54, 12014.78,
        12705.15, 12657.53, 12823.74, 12851.88, 12593.67, 12750.72, 13676.74,
        13776.37, 13726.01, 13876.64, 13773.86, 13880.8, 13924.11, 13926.8,
        13908.28, 14385.43, 13758.28, 14031.87, 14354.31, 14258.47, 14176.66,
        14165.68, 13909.44, 13848.81, 14060.24, 13878.69, 13175.8, 13056.99,
        13210.31, 13192.16, 13098.85, 13430.14, 14618.88, 14523.96, 14938.21,
        14906.83, 14824.42, 14953.58, 14759.63, 14588.08, 14446.09, 13994.39,
        13993.69, 14210.21, 14213.65, 14019.5, 14243.82, 14200.85, 13510.82,
        13501.75, 13578.64, 13579.01, 13380.47, 13196.78, 12313.9, 12164.8,
        12307.84, 13136.39, 14648.87, 14949.85, 14745.7, 15121.29, 16653.71,
        16288.82, 17016.64, 16919.38, 16967.17, 16469.65, 17077.9, 16523.42,
        16613.78, 16972.69, 16403.53, 16475.19, 17184.03, 16886.93, 17293.52,
        17261.82, 17012.36, 16827.26, 17061.66, 17054.41, 16953.07, 16921.66,
        16910.65, 17173.11, 17913.16, 18318.56, 18062.87, 18338.13, 18422.41,
        18371.36, 18359.28, 17857.65, 18383.38, 17599.73, 17099.08, 16504.06,
        16859.51, 16701.19, 16623.8, 17089.49, 17128.84, 17786.77, 17762.64,
        17675.06, 17782.84, 16960.41, 17394.36, 17618.45, 17420.37, 17910.56,
        17453.8, 17395.75, 16738.32, 16693.85, 16748.78, 16284.8, 16152.2,
        16276.39, 16282.95, 16530.76, 16342.01, 16545.04, 16277.49, 16265.04,
        16383.77, 16194.59, 16265.56, 16461.01, 15948.69, 16012.78, 16152.95,
        16223.95, 17003.03, 16760.19, 16790.04, 16454.39, 16270.96, 16502.15,
        16386.31, 16484.7, 15562.61, 16435.41, 15904.71, 16067.22, 16035.95,
        15662.16, 15674.64, 15695.3, 15640.86, 15153.59, 15513.35, 15961.98,
        16082.82, 15981.19, 16194.7, 17077.52, 18228.24, 18115.2, 18548.83,
        18470.84, 18462.09, 18296.63, 18513.87, 18223.03, 18406.67, 18447.44,
        18508.55, 18553.14, 18837.27, 18655.25, 18423.37, 18260.16, 18342.95,
        18294.78, 18264.24, 18369.16, 18512.74, 18382.08, 18951.35, 18296.66,
        18331.68, 18353.24, 18223.53, 18049.75, 18081.91, 18046.31, 18113.55,
        18042.7, 18167.12, 17651.22, 17678.58, 17841.73, 17676.07, 17730.36,
        17766.89, 17648.04, 17680.72, 17707.36, 17666.19, 17696.01, 17588.49,
        17608.45, 17614.14, 17663.27, 18018.48, 17917.95, 17835.08, 17816.65,
        17811.76, 17746.95, 17818.73, 17664.74, 17499.14, 16222.73, 15781.29,
        15770.4, 15864.51, 15817.62, 15621.5, 16013.73, 15810.19, 15770.4,
        15756.01, 15791.75, 15774.88, 16729.36, 16488.89, 15770.37, 15595.06,
        15653.89, 15707.81, 15601.53, 15597.46, 15585.32, 15933.83, 15680.65,
        15682.36, 15648.35, 15222.4, 15719.57, 15884.31, 16101.6, 16207.62,
        16076.69, 16035.52, 16265.61, 16457.68, 16401.08, 16094.87, 16091.46,
        16078.02, 16034.83, 15908.32, 15833.13, 15939.96, 16349.67, 16274.75,
        16349.24, 16946.16, 16678.68, 16587.69, 16798.65, 16606.47, 16930.91,
        16918.68, 16899.3, 16698.15, 16614.12, 16212.36, 16194.76, 16240.04,
        16251.89, 16404.86, 17256.76, 17200.68, 17156.29, 17341.54, 17954.1,
        18156.89, 18182.49, 20166.03, 20674.31, 20947.2, 20717.94, 20462.99,
        20643.27, 21005.28, 20863.72, 20918.53, 21355.03, 21057.55, 20960.32,
        21282.24, 21168.08, 21255.43, 21356.13, 21659.74, 22122.76, 22608.87,
        22330.55, 22490.2, 22073.52, 21553.4, 22823.71, 21922.4, 22120.45,
        22096.96, 22384.06, 22653.72, 22019.23, 22665.88, 22580.58, 22808.89,
        22834.84, 22791.41, 22446.36, 22917.22, 22874.57, 22816.08, 23427.31,
        23817.84, 24248.61, 25392.56, 26805.82, 26491.93, 26110.1, 26790.76,
        26572.63, 26502.01, 24990.45, 24956.5, 26005.0, 26070.77, 25459.48,
        25610.9, 25251.7, 25731.83, 25624.42, 26397.99, 26602.45, 26512.01,
        26479.24, 26014.02, 26439.45, 25706.95, 26223.77, 25801.43, 25277.82,
        25502.8
      ],
      ETH: [
        10000.0, 10040.47, 10158.17, 10137.95, 10477.53, 10456.14, 10614.37,
        10559.45, 10612.79, 11042.75, 11155.95, 11280.18, 11892.27, 12149.86,
        12966.67, 13020.93, 13208.64, 13159.9, 12802.16, 12970.38, 13810.05,
        13617.93, 13615.84, 13605.48, 13059.5, 13258.36, 13390.71, 13316.8,
        13080.73, 13735.48, 13074.52, 13294.9, 13692.57, 13840.98, 13937.7,
        14044.63, 13662.3, 13650.45, 13921.79, 13801.33, 12857.43, 12610.97,
        12843.14, 12651.53, 12469.62, 12995.77, 13925.72, 13761.12, 14201.18,
        14127.98, 14091.02, 14152.63, 13820.06, 13664.84, 13769.48, 13416.63,
        13306.53, 13656.22, 13635.74, 13479.92, 13848.39, 13783.62, 13099.68,
        13054.4, 13088.15, 13119.84, 12985.24, 12920.97, 11975.87, 11905.74,
        12202.84, 13077.47, 14047.13, 14223.53, 13779.53, 13983.5, 14815.3,
        14790.14, 15089.43, 14608.9, 14995.63, 14435.19, 15118.84, 14573.46,
        14528.05, 14847.82, 14291.16, 14801.01, 14968.56, 14917.98, 15268.02,
        15242.41, 14992.71, 15100.56, 15607.84, 15966.97, 15628.22, 15614.85,
        15473.72, 15597.96, 15916.8, 15834.56, 15992.55, 16781.47, 17595.63,
        17516.15, 17776.81, 17385.99, 17493.08, 16435.53, 16243.26, 15424.82,
        15695.12, 15563.76, 15374.44, 15613.01, 15591.2, 15928.34, 15834.31,
        15913.29, 15820.18, 15265.07, 15665.84, 15941.05, 15643.78, 16624.32,
        15845.18, 15946.4, 15441.37, 15415.29, 15402.42, 14961.53, 15068.3,
        15063.1, 15047.98, 15241.58, 15243.42, 15217.66, 15121.03, 15150.95,
        15201.11, 15089.53, 15203.62, 15502.56, 15042.63, 15108.67, 15267.57,
        15299.17, 15939.97, 15818.85, 15920.57, 15628.16, 15611.68, 15935.44,
        15811.65, 15897.25, 15107.75, 15738.51, 15296.25, 15461.36, 15369.49,
        14662.33, 14662.07, 14571.28, 14503.39, 13793.73, 13923.41, 14380.84,
        14467.04, 14399.22, 14490.43, 14923.56, 15827.12, 15637.25, 15803.19,
        15674.3, 15885.11, 15540.88, 15766.9, 15280.22, 15479.08, 16172.97,
        16073.9, 16235.33, 16346.41, 16231.31, 15957.27, 15621.28, 15614.85,
        15557.17, 15605.56, 15685.58, 15708.31, 15644.95, 16646.37, 16104.58,
        16122.89, 16162.92, 15965.64, 15894.65, 15784.81, 15837.64, 15825.94,
        15751.37, 15746.02, 15446.98, 15547.06, 15654.97, 15520.22, 15683.4,
        15714.52, 15514.61, 15519.3, 15476.83, 15403.94, 15363.88, 15286.9,
        15368.32, 15299.77, 15279.71, 15527.66, 15512.36, 15479.24, 15437.53,
        15462.95, 15374.91, 15430.34, 15284.97, 15149.87, 14172.6, 13897.33,
        13931.27, 14076.48, 13936.78, 13540.26, 14012.75, 13831.19, 13826.91,
        13781.68, 13855.94, 13801.93, 14462.1, 14224.19, 13790.97, 13598.95,
        13683.63, 13674.95, 13579.14, 13641.26, 13640.15, 13798.0, 13675.93,
        13672.51, 13538.51, 12940.43, 13351.41, 13433.01, 13626.71, 13789.73,
        13676.69, 13548.78, 13727.52, 13726.95, 13568.02, 13257.54, 13332.01,
        13296.84, 13286.38, 13266.98, 13274.75, 13361.01, 13796.07, 13936.27,
        14002.32, 14500.64, 13888.29, 13857.11, 13781.94, 13507.58, 13751.67,
        13669.84, 13636.22, 13183.47, 13109.48, 13086.66, 12873.56, 12953.49,
        13011.17, 13001.12, 13392.13, 13059.72, 13079.97, 13076.71, 13445.21,
        13630.45, 13932.5, 14925.49, 14994.3, 14987.45, 15108.73, 14855.52,
        14854.44, 15090.76, 15125.88, 15123.22, 15410.28, 15013.69, 15308.64,
        15531.09, 15763.16, 15909.61, 15712.49, 15809.31, 17506.35, 17402.79,
        17095.98, 17228.13, 17210.26, 16609.42, 17161.27, 16451.19, 16406.94,
        16361.88, 16559.51, 16920.92, 16238.41, 17262.01, 17270.79, 17337.82,
        17412.64, 17361.24, 16889.64, 17130.33, 16962.56, 17082.1, 17434.64,
        18039.72, 18433.3, 18626.43, 19074.51, 18674.25, 19644.23, 19761.52,
        19674.24, 19740.95, 18586.46, 18292.43, 18899.13, 19352.73, 18650.92,
        18633.68, 18549.58, 18498.17, 18206.32, 18325.96, 18765.87, 19336.35,
        19197.89, 18890.01, 19102.02, 18625.41, 19768.87, 19705.93, 19074.1,
        19125.92
      ],
      SOL: [
        10000.0, 10020.11, 11336.86, 13437.24, 13618.24, 13497.57, 13437.24,
        13105.58, 13778.95, 16392.17, 16372.06, 16271.5, 16472.44, 18160.89,
        23738.91, 23266.47, 23769.07, 23306.69, 21256.42, 21537.8, 25346.92,
        24934.82, 24331.83, 24502.78, 23105.76, 24321.78, 24432.39, 24231.28,
        23980.07, 26402.22, 23879.51, 24161.07, 25065.54, 24703.71, 24824.38,
        24743.94, 23698.69, 23115.82, 23769.07, 23266.47, 20372.06, 20100.73,
        20884.54, 21688.63, 20542.82, 21809.3, 23889.57, 22522.71, 23296.64,
        23708.74, 25326.81, 26362.0, 24975.04, 24231.28, 23949.9, 22924.76,
        22321.78, 23176.15, 22814.32, 22251.39, 22522.71, 22171.12, 21487.7,
        20824.2, 21156.04, 20814.32, 20150.83, 18462.38, 17216.19, 18181.0,
        18040.4, 19929.79, 20643.38, 20995.15, 19336.86, 19728.85, 21296.64,
        21638.35, 22110.79, 22532.77, 22583.05, 21547.85, 22151.01, 20653.44,
        20442.45, 21135.93, 19909.68, 20542.82, 21226.25, 20532.77, 21507.63,
        21276.53, 20653.44, 20552.88, 21055.48, 21025.32, 20693.66, 20593.1,
        20040.4, 20462.56, 20944.87, 23316.75, 23799.24, 24512.83, 24834.44,
        24241.33, 25548.03, 24914.7, 24864.6, 22995.15, 22341.89, 21346.92,
        21970.01, 21588.07, 21467.58, 21809.3, 21316.75, 22362.0, 23377.08,
        23166.09, 23256.42, 22080.62, 22372.06, 22281.56, 21769.07, 22964.98,
        22020.29, 22271.5, 20753.99, 20713.77, 21145.98, 20271.5, 20814.32,
        21005.2, 21055.48, 21216.19, 20753.99, 21085.65, 20552.88, 20462.56,
        20341.89, 19759.02, 19698.69, 20140.95, 19336.86, 19326.81, 19477.46,
        20472.61, 21055.48, 20683.6, 21346.92, 20874.48, 20663.49, 21427.36,
        21246.36, 22201.11, 20090.68, 20492.55, 18673.55, 19025.32, 17537.8,
        15819.18, 15588.07, 15276.53, 14995.15, 14482.49, 14844.32, 15427.19,
        15809.12, 15457.35, 16050.45, 16603.16, 17346.92, 16703.71, 17125.69,
        16633.32, 17105.58, 16362.0, 16743.94, 16080.62, 17990.12, 19236.31,
        18532.77, 19608.19, 19165.92, 19557.91, 18924.76, 20402.22, 21608.19,
        22020.29, 21507.63, 21377.08, 22120.84, 22090.68, 25909.68, 26462.56,
        27477.64, 27738.91, 26764.05, 25628.3, 26522.89, 25638.35, 25879.69,
        25306.69, 24804.27, 23527.92, 23276.53, 25457.53, 25256.42, 25025.32,
        25387.14, 24221.22, 23899.62, 23789.19, 23336.86, 22834.44, 22924.76,
        22774.1, 23367.03, 23156.04, 24221.22, 24472.61, 24733.88, 24693.66,
        25115.82, 24412.28, 25206.32, 23959.95, 22954.93, 22110.79, 21507.63,
        21899.62, 21909.68, 21356.97, 20442.45, 21638.35, 21045.43, 20462.56,
        20422.33, 20904.65, 20623.27, 21959.95, 20944.87, 19889.57, 19356.97,
        19598.13, 19608.19, 19437.42, 20321.78, 19688.63, 20030.34, 19698.69,
        19588.07, 18432.39, 17658.46, 18100.73, 18512.66, 19035.37, 19246.36,
        19165.92, 18884.54, 19909.68, 20160.89, 20392.17, 19648.41, 19567.96,
        19507.63, 19608.19, 19467.58, 19035.37, 19155.86, 19939.84, 20382.11,
        21527.74, 24502.78, 23457.53, 23678.57, 23316.75, 22874.48, 23628.3,
        23417.31, 23356.97, 22201.11, 22261.44, 22070.57, 21447.47, 21899.62,
        22140.95, 21980.07, 24261.44, 24100.73, 23748.96, 24934.82, 27467.58,
        29879.69, 29407.25, 32573.17, 30472.61, 32332.01, 33025.32, 31738.91,
        31789.19, 33125.87, 35125.87, 38130.9, 42522.89, 40231.45, 39940.02,
        42623.45, 41136.1, 41497.75, 43276.7, 43467.76, 45628.47, 56905.0,
        58492.9, 57367.38, 52502.95, 57457.7, 65437.77, 58372.41, 59015.61,
        58633.68, 61025.67, 56724.0, 52080.8, 58291.96, 57126.05, 57156.21,
        58945.22, 58121.02, 55015.61, 58121.02, 59457.7, 59558.26, 60412.63,
        63176.33, 64060.86, 61236.66, 60744.29, 63045.78, 68251.74, 73900.15,
        74261.97, 74553.41, 70080.97, 68080.97, 71658.82, 76101.08, 75065.89,
        74352.3, 72563.46, 74915.23, 73196.61, 82040.93, 95045.96, 97548.56,
        109357.68, 112523.6, 120342.77, 112222.1, 107749.67, 104272.21,
        105508.34, 102322.48
      ],
      USDT: [
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0
      ],
      HODLIE: [
        10000.0, 9992.6, 10398.94, 10998.63, 11041.27, 11009.51, 10990.65,
        10993.81, 11027.99, 11683.01, 11665.43, 11637.67, 11691.23, 11962.05,
        12438.81, 12360.78, 12429.75, 12344.48, 12151.39, 12071.34, 12073.5,
        11961.67, 11863.74, 11882.27, 11822.26, 11789.33, 11773.31, 11774.84,
        11701.57, 11835.58, 11489.7, 11519.61, 11495.26, 11437.53, 11477.47,
        11520.46, 11456.13, 11454.77, 11455.95, 11383.23, 10998.23, 11019.12,
        11020.03, 11020.86, 10832.8, 10984.56, 11321.12, 11259.21, 11349.73,
        11343.83, 11311.36, 11467.02, 11263.76, 11160.52, 11164.79, 11163.39,
        11163.37, 11164.52, 11163.84, 11089.37, 11237.51, 11211.6, 10925.25,
        10924.93, 10925.14, 10933.2, 10854.97, 10872.6, 10857.56, 10858.43,
        10857.76, 10860.52, 11020.24, 11123.09, 11048.27, 11188.79, 11740.4,
        11613.92, 11867.49, 11728.53, 11723.65, 11552.29, 11557.67, 11331.76,
        11347.33, 11416.04, 11383.85, 11461.42, 11525.69, 11440.97, 11585.16,
        11538.56, 11440.5, 11354.59, 11353.2, 11481.81, 11362.32, 11298.11,
        11297.93, 11298.27, 11542.21, 11670.93, 11769.16, 11874.95, 11944.56,
        11918.87, 12007.92, 11862.93, 11765.32, 11528.58, 11527.17, 11527.46,
        11529.3, 11527.71, 11528.55, 11662.0, 11674.37, 11892.46, 11883.43,
        11830.59, 11845.56, 11683.36, 11677.74, 11681.06, 11574.27, 11875.19,
        11600.74, 11599.31, 11597.49, 11597.1, 11597.71, 11596.0, 11596.25,
        11590.74, 11599.79, 11646.35, 11580.57, 11474.57, 11364.69, 11345.77,
        11356.9, 11355.25, 11393.49, 11503.58, 11334.7, 11331.7, 11331.97,
        11337.13, 11430.89, 11325.88, 11453.21, 11358.1, 11333.0, 11320.16,
        11271.06, 11409.78, 11051.16, 11051.56, 10972.94, 10973.7, 10968.17,
        10957.08, 10956.13, 10955.27, 10794.86, 10644.36, 10657.67, 10654.58,
        10696.25, 10662.59, 10748.76, 10987.19, 11347.3, 11310.11, 11442.98,
        11417.55, 11415.62, 11235.79, 11201.64, 11128.36, 11098.36, 11343.99,
        11213.22, 11420.54, 11397.01, 11411.22, 11261.02, 11336.74, 11562.96,
        11640.16, 11538.78, 11515.94, 11512.43, 11470.39, 12113.29, 12214.77,
        12388.48, 12423.64, 12354.2, 12077.41, 12077.39, 11990.55, 12044.01,
        12008.44, 12007.14, 11838.83, 11838.72, 11838.93, 11824.09, 11789.76,
        11840.72, 11755.52, 11751.32, 11754.37, 11649.67, 11645.3, 11619.62,
        11626.78, 11631.61, 11594.07, 11757.31, 11793.43, 11830.46, 11817.52,
        11813.37, 11717.22, 11790.79, 11603.62, 11608.95, 11606.09, 11604.78,
        11604.78, 11604.63, 11503.82, 11425.99, 11477.06, 11327.01, 11315.96,
        11295.97, 11287.23, 11287.11, 11522.29, 11407.78, 11163.66, 11069.47,
        11073.89, 11073.67, 11049.19, 11169.69, 11059.8, 11020.89, 10944.65,
        10945.03, 10932.8, 10924.96, 10895.87, 10870.22, 10922.54, 10900.73,
        10878.51, 10848.66, 10913.12, 10964.02, 11004.08, 10877.34, 10860.3,
        10860.06, 10844.58, 10816.69, 10815.35, 10713.74, 10883.98, 10937.95,
        10963.16, 11431.58, 11266.61, 11303.06, 11251.37, 11235.39, 11273.7,
        11269.17, 11262.59, 11168.11, 11168.71, 11168.17, 11167.71, 11167.88,
        11167.92, 11159.1, 11536.4, 11501.74, 11449.89, 11635.14, 12016.41,
        12359.7, 12300.98, 12733.19, 12582.45, 12662.74, 12595.14, 12435.89,
        12450.5, 12534.12, 12519.81, 12863.25, 13332.13, 13117.22, 13081.47,
        13361.14, 13267.86, 13330.64, 13216.28, 13209.87, 13437.95, 13849.33,
        13976.65, 13891.78, 13512.56, 13459.72, 14088.06, 13586.23, 13635.26,
        13595.25, 13601.45, 13522.16, 13246.87, 13368.57, 13370.75, 13396.23,
        13444.83, 13353.6, 13103.78, 13104.65, 13040.65, 13044.84, 13102.85,
        13257.17, 13398.46, 13547.06, 13916.07, 13834.78, 13737.79, 14136.8,
        14162.08, 14186.66, 13915.45, 13931.74, 13928.74, 14057.48, 13999.22,
        13959.51, 13899.16, 13899.06, 13809.13, 14149.08, 14863.56, 14987.74,
        15582.94, 15724.38, 16080.74, 15733.53, 15589.25, 15522.74, 15262.66,
        15281.03
      ]
    },
    2024: {
      TIMESTAMP: [
        '2024-01-01',
        '2024-01-02',
        '2024-01-03',
        '2024-01-04',
        '2024-01-05',
        '2024-01-06',
        '2024-01-07',
        '2024-01-08',
        '2024-01-09',
        '2024-01-10',
        '2024-01-11',
        '2024-01-12',
        '2024-01-13',
        '2024-01-14',
        '2024-01-15',
        '2024-01-16',
        '2024-01-17',
        '2024-01-18',
        '2024-01-19',
        '2024-01-20',
        '2024-01-21',
        '2024-01-22',
        '2024-01-23',
        '2024-01-24',
        '2024-01-25',
        '2024-01-26',
        '2024-01-27',
        '2024-01-28',
        '2024-01-29',
        '2024-01-30',
        '2024-01-31',
        '2024-02-01',
        '2024-02-02',
        '2024-02-03',
        '2024-02-04',
        '2024-02-05',
        '2024-02-06',
        '2024-02-07',
        '2024-02-08',
        '2024-02-09',
        '2024-02-10',
        '2024-02-11',
        '2024-02-12',
        '2024-02-13',
        '2024-02-14',
        '2024-02-15',
        '2024-02-16',
        '2024-02-17',
        '2024-02-18',
        '2024-02-19',
        '2024-02-20',
        '2024-02-21',
        '2024-02-22',
        '2024-02-23',
        '2024-02-24',
        '2024-02-25',
        '2024-02-26',
        '2024-02-27',
        '2024-02-28',
        '2024-02-29',
        '2024-03-01',
        '2024-03-02',
        '2024-03-03',
        '2024-03-04',
        '2024-03-05',
        '2024-03-06',
        '2024-03-07',
        '2024-03-08',
        '2024-03-09',
        '2024-03-10',
        '2024-03-11',
        '2024-03-12',
        '2024-03-13',
        '2024-03-14',
        '2024-03-15',
        '2024-03-16',
        '2024-03-17',
        '2024-03-18',
        '2024-03-19',
        '2024-03-20',
        '2024-03-21',
        '2024-03-22',
        '2024-03-23',
        '2024-03-24',
        '2024-03-25',
        '2024-03-26',
        '2024-03-27',
        '2024-03-28',
        '2024-03-29',
        '2024-03-30',
        '2024-03-31',
        '2024-04-01',
        '2024-04-02',
        '2024-04-03',
        '2024-04-04',
        '2024-04-05',
        '2024-04-06',
        '2024-04-07',
        '2024-04-08',
        '2024-04-09',
        '2024-04-10',
        '2024-04-11',
        '2024-04-12',
        '2024-04-13',
        '2024-04-14',
        '2024-04-15',
        '2024-04-16',
        '2024-04-17',
        '2024-04-18',
        '2024-04-19',
        '2024-04-20',
        '2024-04-21',
        '2024-04-22',
        '2024-04-23',
        '2024-04-24',
        '2024-04-25',
        '2024-04-26',
        '2024-04-27',
        '2024-04-28',
        '2024-04-29',
        '2024-04-30',
        '2024-05-01',
        '2024-05-02',
        '2024-05-03',
        '2024-05-04',
        '2024-05-05',
        '2024-05-06',
        '2024-05-07',
        '2024-05-08',
        '2024-05-09',
        '2024-05-10',
        '2024-05-11',
        '2024-05-12',
        '2024-05-13',
        '2024-05-14',
        '2024-05-15',
        '2024-05-16',
        '2024-05-17',
        '2024-05-18',
        '2024-05-19',
        '2024-05-20',
        '2024-05-21',
        '2024-05-22',
        '2024-05-23',
        '2024-05-24',
        '2024-05-25',
        '2024-05-26',
        '2024-05-27',
        '2024-05-28',
        '2024-05-29',
        '2024-05-30',
        '2024-05-31',
        '2024-06-01',
        '2024-06-02',
        '2024-06-03',
        '2024-06-04',
        '2024-06-05',
        '2024-06-06',
        '2024-06-07',
        '2024-06-08',
        '2024-06-09',
        '2024-06-10',
        '2024-06-11',
        '2024-06-12',
        '2024-06-13',
        '2024-06-14',
        '2024-06-15',
        '2024-06-16',
        '2024-06-17',
        '2024-06-18',
        '2024-06-19',
        '2024-06-20',
        '2024-06-21',
        '2024-06-22',
        '2024-06-23',
        '2024-06-24',
        '2024-06-25',
        '2024-06-26',
        '2024-06-27',
        '2024-06-28',
        '2024-06-29',
        '2024-06-30',
        '2024-07-01',
        '2024-07-02',
        '2024-07-03',
        '2024-07-04',
        '2024-07-05',
        '2024-07-06',
        '2024-07-07',
        '2024-07-08',
        '2024-07-09',
        '2024-07-10',
        '2024-07-11',
        '2024-07-12',
        '2024-07-13',
        '2024-07-14',
        '2024-07-15',
        '2024-07-16',
        '2024-07-17',
        '2024-07-18',
        '2024-07-19',
        '2024-07-20',
        '2024-07-21',
        '2024-07-22',
        '2024-07-23',
        '2024-07-24',
        '2024-07-25',
        '2024-07-26',
        '2024-07-27',
        '2024-07-28',
        '2024-07-29',
        '2024-07-30',
        '2024-07-31'
      ],
      BTC: [
        10000.0, 10301.02, 10644.57, 10116.38, 10487.51, 10404.55, 10383.54,
        10379.8, 11122.18, 10945.65, 11099.16, 10980.2, 10059.66, 10173.41,
        10003.11, 10087.58, 10224.37, 10109.86, 9786.26, 9864.92, 9887.28,
        9852.22, 9426.12, 9339.96, 9436.35, 9455.35, 9916.8, 9975.78, 9899.7,
        10201.91, 10253.18, 10094.23, 10158.37, 10223.55, 10183.03, 10074.24,
        10058.42, 10222.94, 10450.28, 10713.39, 11186.7, 11317.63, 11391.01,
        11837.11, 11718.89, 12256.28, 12221.25, 12300.16, 12236.89, 12362.14,
        12290.35, 12345.31, 12189.71, 12176.67, 12018.59, 12200.47, 12234.88,
        12934.81, 13463.27, 14500.23, 14488.19, 14787.79, 14597.29, 14867.63,
        16074.9, 15002.64, 15598.64, 15879.21, 16115.83, 16178.05, 16190.72,
        17142.63, 16839.94, 17263.48, 16929.2, 16352.99, 15703.84, 16145.63,
        16018.79, 14821.84, 16016.4, 15474.55, 14913.66, 15322.08, 15843.49,
        16638.32, 16623.93, 16432.48, 16765.63, 16519.52, 16498.91, 16794.51,
        16496.75, 15574.83, 15651.52, 16076.77, 16003.76, 16341.57, 16375.64,
        17018.39, 16405.38, 16689.91, 16600.75, 15867.65, 15214.54, 15459.91,
        15017.31, 15093.66, 14598.45, 15026.72, 15148.06, 15290.29, 15388.94,
        15861.65, 15704.25, 15167.82, 15287.79, 15102.96, 14969.99, 14944.21,
        15119.07, 14338.1, 13696.99, 14039.52, 14856.98, 15053.74, 15111.98,
        15025.76, 14873.19, 14494.57, 14974.84, 14400.63, 14413.88, 14512.32,
        14854.04, 14602.91, 15645.91, 15480.89, 15800.98, 15838.94, 15704.43,
        16468.12, 16617.54, 16361.75, 16017.48, 16278.15, 16358.84, 16224.2,
        16468.31, 16237.3, 16015.95, 16166.2, 15975.61, 16036.16, 16074.63,
        16338.37, 16697.78, 16835.87, 16778.17, 16446.41, 16408.33, 16478.43,
        16445.68, 15940.27, 16173.51, 15830.42, 15621.7, 15665.72, 15797.29,
        15773.62, 15448.0, 15342.94, 15379.36, 15181.78, 15219.83, 15064.17,
        14210.12, 14650.52, 14417.34, 14586.63, 14285.62, 14428.08, 14885.04,
        14902.57, 14693.12, 14273.32, 13734.45, 13398.94, 13750.44, 13345.56,
        13413.94, 13710.1, 13656.79, 13568.73, 13676.03, 14071.83, 14402.38,
        15226.42, 15291.09, 15208.8, 15152.68, 15789.05, 15882.0, 16094.51,
        16005.3, 15598.99, 15449.9, 15534.14, 16074.16, 16238.39, 16094.09,
        15908.83, 15670.92
      ],
      ETH: [
        10000.0, 10261.29, 10370.41, 9695.0, 9979.02, 9881.21, 9835.93, 9743.36,
        10276.32, 10290.7, 11199.36, 11497.51, 10983.97, 11333.75, 11028.98,
        11051.58, 11401.37, 11115.89, 10860.56, 10950.12, 10866.36, 10823.85,
        10201.55, 9787.4, 9765.29, 9765.42, 9970.27, 9963.47, 9888.43, 10161.15,
        10396.48, 10048.26, 10097.94, 10149.98, 10103.87, 10058.86, 10087.31,
        10470.29, 10662.83, 10657.25, 10969.1, 10972.48, 10991.78, 11671.81,
        11587.46, 12185.93, 12367.88, 12260.75, 12245.32, 12660.61, 13018.94,
        13166.38, 12898.87, 13134.9, 12849.24, 13137.01, 13638.3, 14001.33,
        14276.82, 14684.24, 14606.35, 15092.55, 14964.15, 15271.08, 15863.05,
        15414.78, 16763.98, 16955.59, 17042.95, 17149.32, 16809.16, 17882.2,
        17363.47, 17509.94, 17046.15, 16396.37, 15641.09, 16025.74, 15484.24,
        14116.98, 15476.11, 15312.31, 14450.68, 14845.89, 15129.16, 15877.42,
        15847.84, 15452.76, 15679.34, 15482.39, 15414.3, 15956.76, 15404.67,
        14499.66, 14588.55, 14560.94, 14571.32, 14851.61, 15038.87, 16358.74,
        15468.36, 15554.14, 15410.92, 14150.66, 13270.83, 13876.96, 13745.69,
        13599.62, 13225.91, 13497.39, 13495.16, 13807.98, 13872.21, 14149.94,
        14135.1, 13769.3, 13890.62, 13770.18, 14266.0, 14465.68, 14135.1,
        13232.59, 13054.32, 13176.76, 13654.13, 13660.28, 13768.37, 13547.69,
        13349.21, 13052.66, 13427.02, 12772.98, 12818.83, 12889.07, 12925.57,
        12702.78, 13246.82, 12922.35, 13588.14, 13706.52, 13526.86, 15878.34,
        16765.65, 16462.98, 16753.33, 16429.7, 16430.93, 16872.28, 17091.21,
        16981.92, 16614.16, 16473.93, 16574.76, 16801.08, 16627.16, 16610.51,
        16768.23, 16928.11, 16757.41, 16243.65, 16162.91, 16299.39, 16153.84,
        15388.8, 15700.49, 15306.11, 15320.31, 15696.65, 15951.54, 15491.71,
        15299.51, 15636.78, 15472.01, 15489.78, 15397.15, 15058.27, 14650.58,
        14960.36, 14860.14, 15171.89, 14833.68, 14843.99, 15169.22, 15137.96,
        15055.1, 14515.71, 13751.24, 13088.52, 13471.11, 12913.82, 13296.68,
        13450.67, 13631.88, 13640.85, 13747.67, 14031.82, 14269.08, 15266.33,
        15072.58, 14925.37, 15094.22, 15422.83, 15448.58, 15533.39, 15158.36,
        15308.74, 14635.55, 13913.67, 14417.53, 14397.89, 14329.93, 14636.15,
        14414.82
      ],
      SOL: [
        10000.0, 10752.0, 10741.06, 9828.91, 10422.75, 9868.7, 9290.76, 8881.92,
        9748.33, 9900.53, 10272.54, 9977.11, 8979.4, 9566.3, 9616.03, 9371.32,
        9762.26, 10066.65, 9416.09, 9294.74, 9262.91, 9100.75, 8352.73, 8224.41,
        8703.86, 8680.99, 9181.33, 9319.6, 9434.0, 10037.78, 10291.45, 9718.49,
        9660.8, 9980.1, 9743.35, 9526.51, 9425.04, 9694.62, 10042.76, 10208.88,
        10731.12, 10881.32, 10697.3, 11099.17, 11144.92, 11650.24, 11142.94,
        10913.15, 10865.41, 11220.52, 11146.91, 10827.61, 10406.83, 10242.7,
        9930.37, 10357.1, 10296.43, 10909.17, 10788.81, 11521.92, 12299.8,
        12997.1, 12762.35, 13025.95, 13010.04, 12125.72, 12999.09, 14397.68,
        14334.01, 14417.58, 14108.22, 14708.04, 14757.78, 16259.81, 17200.83,
        18316.9, 18005.56, 20008.94, 19710.52, 16835.77, 18891.86, 17736.99,
        16941.19, 17382.85, 18399.47, 18996.3, 19123.63, 18598.41, 18892.85,
        19122.63, 19514.56, 19956.23, 19182.33, 18312.94, 18515.86, 18132.88,
        17348.04, 17765.83, 17762.85, 18018.48, 17307.27, 17304.28, 17183.91,
        15071.11, 14026.64, 14981.59, 13920.22, 13686.46, 13387.05, 14213.66,
        14290.26, 14838.34, 14816.46, 15719.68, 15446.12, 14614.53, 14451.4,
        13847.6, 13993.82, 13833.67, 13691.42, 12670.84, 13267.67, 13800.84,
        14261.4, 14523.02, 14439.46, 15377.49, 14922.9, 14078.38, 15214.35,
        14505.11, 14503.12, 14247.48, 14614.53, 14230.56, 15505.8, 15802.23,
        16776.07, 17111.29, 17051.61, 18462.14, 17755.88, 17656.41, 17530.08,
        16636.82, 16704.46, 16289.65, 17024.76, 16813.87, 16874.54, 16631.84,
        16527.4, 16583.1, 16242.9, 16457.76, 16990.94, 17226.69, 16965.08,
        16223.01, 15766.44, 16106.62, 15852.97, 14869.18, 15477.95, 14738.87,
        14256.44, 14470.29, 14960.71, 14306.17, 13691.42, 13399.97, 13321.4,
        13411.91, 13356.19, 12853.86, 12952.35, 13617.82, 13667.55, 14941.8,
        13929.16, 13932.15, 14626.48, 14601.6, 15390.42, 14061.46, 13176.16,
        13353.23, 14311.14, 13234.84, 13956.03, 14059.47, 14082.36, 13604.88,
        13804.82, 14145.02, 14640.39, 15794.28, 15907.67, 15517.75, 15912.65,
        16790.0, 17246.57, 18298.01, 17757.87, 17199.83, 17643.49, 17138.16,
        18227.38, 18471.09, 18246.29, 18234.35, 17838.45
      ],
      USDT: [
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0
      ],
      HODLIE: [
        10000.0, 10078.89, 10164.88, 9917.09, 10094.9, 10030.49, 10020.42,
        10004.25, 10264.23, 10183.24, 10399.69, 10537.81, 10307.05, 10466.37,
        10382.89, 10387.5, 10512.14, 10596.94, 10371.3, 10374.11, 10375.99,
        10378.86, 10282.15, 10280.73, 10279.92, 10210.44, 10406.23, 10456.72,
        10389.85, 10606.04, 10697.42, 10514.35, 10509.55, 10497.13, 10473.33,
        10419.59, 10335.12, 10465.89, 10560.98, 10560.12, 10772.35, 10834.02,
        10867.96, 11077.7, 11012.71, 11291.08, 11376.02, 11329.28, 11316.31,
        11482.19, 11636.85, 11701.52, 11590.39, 11694.74, 11574.4, 11622.41,
        11839.14, 11996.96, 12158.47, 12613.96, 12610.26, 12527.53, 12453.34,
        12537.22, 12790.79, 12292.54, 12822.35, 12900.62, 12909.85, 12937.06,
        12822.52, 13170.3, 13057.93, 13227.48, 13470.65, 13765.11, 13695.41,
        14180.64, 14115.05, 13633.74, 13639.78, 13639.22, 13421.23, 13595.86,
        13507.18, 13529.15, 13511.81, 13435.15, 13569.06, 13503.07, 13571.37,
        13672.72, 13496.02, 13194.43, 13194.98, 13193.6, 13287.61, 13421.5,
        13434.31, 13949.25, 13573.44, 13613.41, 13579.38, 13249.36, 13223.81,
        13223.97, 13222.84, 13220.84, 13056.82, 13004.7, 13049.09, 13215.42,
        13212.43, 13473.92, 13397.51, 13091.75, 13107.07, 13099.34, 13098.9,
        13163.8, 13017.42, 12599.5, 12597.62, 12593.39, 12733.92, 12809.41,
        12803.14, 12767.64, 12655.39, 12525.89, 12528.41, 12358.24, 12363.93,
        12298.55, 12301.06, 12176.3, 12601.36, 12508.38, 12758.31, 12842.04,
        12828.53, 13170.98, 13461.56, 13343.03, 13460.54, 13286.35, 13278.43,
        13405.47, 13506.02, 13463.67, 13404.76, 13311.71, 13311.63, 13312.21,
        13250.24, 13289.26, 13449.47, 13504.92, 13454.86, 13319.66, 13302.57,
        13301.69, 13296.14, 13037.48, 13039.25, 13037.22, 12941.85, 12995.7,
        13099.29, 12913.81, 12710.23, 12711.12, 12568.77, 12575.54, 12518.28,
        12517.22, 12515.44, 12487.98, 12504.13, 12883.24, 12593.31, 12593.39,
        12798.21, 12800.51, 12920.53, 12549.61, 12528.51, 12525.31, 12529.3,
        12233.36, 12455.86, 12450.54, 12533.45, 12538.0, 12475.23, 12583.73,
        12720.32, 13077.87, 13105.04, 13070.48, 13047.6, 13294.92, 13414.29,
        13682.54, 13549.45, 13411.12, 13520.87, 13534.78, 13641.79, 13702.31,
        13599.8, 13600.49, 13501.98
      ]
    }
  }
};
