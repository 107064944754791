import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import { CreateBot as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { breakpoints } from 'helpers/utils';
import portfolioImg from 'assets/img/hodlie/portfolios.png';
import allBotsImg from 'assets/img/hodlie/allBots.png';
import FormTitle from 'pages/common/FormTitle';

const CreateBotCover = () => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const queryParameters = new URLSearchParams(window.location.search);
  const exchangeUrl = queryParameters.get('exchange') || 'binance';

  return (
    <>
      <Row md={12}>
        <FormTitle
          icon="rocket"
          title={textLang.titleInvest[lang]}
          subtitle={textLang.subtitleInvest[lang]}
        />
      </Row>

      <Row
        className="d-flex justify-content-center align-items-center"
        style={
          window.innerWidth < breakpoints.sm
            ? { height: '65vh' }
            : { height: '60vh' }
        }
      >
        <Col sm={6}>
          <Card
            className="py-8 py-sm-10 text-center hover-card mb-3"
            as={Link}
            to={`/invest?exchange=${exchangeUrl}&type=portfolio`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              position: 'relative',
              overflow: 'hidden'
            }}
            disabled={exchangeUrl == 'virtual'}
          >
            <div
              style={{
                backgroundImage: `url(${portfolioImg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                opacity: 0.5,
                zIndex: 0
              }}
            ></div>
            <Card.Header
              as="h4"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                textAlign: 'left',
                padding: '1rem',
                width: 'auto'
              }}
            >
              {textLang.assetManagement[lang]}
              <FontAwesomeIcon icon="arrow-right" className="ms-3" size="md" />
            </Card.Header>
            <h6
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                textAlign: 'left',
                paddingTop: '3.4rem',
                paddingLeft: '1rem',
                width: 'auto'
              }}
            >
              {textLang.assetManagementSubtitle[lang]}
            </h6>
          </Card>
        </Col>
        <Col sm={6}>
          <Card
            className="py-8 py-sm-10 text-center hover-card mb-3"
            as={Link}
            to={`/invest?exchange=${exchangeUrl}&type=bot`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <div
              style={{
                backgroundImage: `url(${allBotsImg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                opacity: 0.5,
                zIndex: 0
              }}
            ></div>
            <Card.Header
              as="h4"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                textAlign: 'left',
                padding: '1rem',
                width: 'auto'
              }}
            >
              {textLang.allProducts[lang]}
              <FontAwesomeIcon icon="arrow-right" className="ms-3" size="md" />
            </Card.Header>
            <h6
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                textAlign: 'left',
                paddingTop: '3.4rem',
                paddingLeft: '1rem',
                width: 'auto'
              }}
            >
              {textLang.allProductsSubtitle[lang]}
            </h6>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreateBotCover;
